import React from 'react'
import { IconButton, type IconButtonProps, styled } from '@mui/material'
import { CopyIcon } from '../../../../../components/Icons'
import { useCopyToClipboard } from '../../../../../hooks'
import { useSidebarContext } from '../../../../../contexts'

interface Props {
  message: string
}

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  p: 0,
  width: 24,
  height: 24,
  color: theme.palette.background.border,
  '&:hover': {
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
  },
}))

const CopyButton = ({ message }: Props) => {
  const { simplifyText } = useSidebarContext()
  const [copyToClipboard]: any = useCopyToClipboard()

  const handleClick = async () => {
    copyToClipboard(simplifyText(message))
    return false
  }

  return (
    <StyledIconButton onClick={handleClick}>
      <CopyIcon />
    </StyledIconButton>
  )
}

export default CopyButton
