import React, { type ChangeEvent, useState, useEffect, useRef, useContext } from 'react'
import { useSpeechRecognition } from 'react-speech-recognition'
import {
  Button,
  Stack,
  Typography,
  CircularProgress,
  InputBase,
  type InputBaseProps,
  styled,
  Alert,
  Snackbar,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MicPhoneButton from './MicPhoneButton'
import InputMessage from './InputMessage'
import { SendIcon } from '../../../../components/Icons'
import ResponseButton from './ResponseButton'
import { useSidebarContext } from '../../../../contexts'

import { toast } from 'react-toastify'
import baseUrl from '../../../../config/baseUrl'

const StyledInputBase = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderWidth: 0.5,
  borderColor: theme.palette.text.primary,
  '& .MuiInputAdornment-positionEnd': {
    paddingRight: theme.spacing(1.5),
  },
}))

interface Props {
  setInputMessage: React.Dispatch<React.SetStateAction<string>>
  setAnswer: React.Dispatch<React.SetStateAction<string>>
  aiStorage: any[] // Assuming aiStorage is an array of objects
  setAiStorage: React.Dispatch<React.SetStateAction<any[]>>
}
const SendMessage = ({ setInputMessage, setAnswer, aiStorage, setAiStorage }: Props) => {
  const { transcript } = useSpeechRecognition()
  const hasMessageValue = false
  // const [message, setMessage] = useState<string>('')
  const {
    userStatus,
    handleSearchCount,
    isLoading,
    setIsLoading,
    inputMessage,
    sendData,
    message,
    setMessage,
    lastAskedQuestion,
    setLastAskedQuestion,
    setIsResponseComplete,
  } = useSidebarContext()

  const promptRef = useRef<HTMLInputElement>(null)

  const token = localStorage.getItem('token')
  const [inputQuestion, setInputQuestion] = useState<string>('')
  const [sendIsLoading, setSendIsLoading] = useState(false)
  const [isSendBtnClicked, setIsSendBtnClicked] = useState(false)
  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  const navigate = useNavigate()

  useEffect(() => {
    console.log('input message ', inputMessage)
    if (inputMessage?.length > 0 && promptRef.current !== null) {
      promptRef.current.value = inputMessage
      setInputQuestion(inputMessage)
    } else {
      setInputQuestion('')
    }
  }, [inputMessage])

  useEffect(() => {
    if (transcript?.length > 0) {
      setMessage(transcript)
      setLastAskedQuestion(transcript)
      setInputQuestion('')
    }
  }, [transcript])

  useEffect(() => {
    // message.length > 0 && setInputQuestion(message)
    if (isSendBtnClicked && promptRef?.current !== null) {
      promptRef.current.value = ''
    }
    message.length > 0 &&
      setTimeout(() => {
        setSendIsLoading(false)
        setIsSendBtnClicked(false)
      }, 1000)
  }, [message])

  const isPaid: string = sessionStorage.getItem('isPaid') ?? ''

  const userId: string = localStorage.getItem('userId') ?? ''

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const handleChange = (event: any) => {
    const { value: inputValue } = event.target

    setInputQuestion(inputValue)
    // setMessage(inputValue)
    // setLastAskedQuestion(inputValue)
  }

  const handleEnterKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSendClick()

      // if (inputQuestion.length !== 0) {
      //   // alert('inside handleSendClick')
      //   setIsLoading(true)
      //   const uStatus = await userStatus(userId)
      //   const promptValue = promptRef.current?.value ?? ''
      //   uStatus?.status && handleSearchCount(promptValue, userId)
      //   // setMessage('')
      // }

      // console.log('Enter pressed:', event.key) // Example action
    }
  }

  const handleSendClick = async () => {
    setIsSendBtnClicked(true)
    if (!userId) {
      setSnackbarMessage('Your session has expired please login again')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
      setTimeout(() => {
        navigate('/')
      }, 3500)
    } else if (promptRef?.current?.value?.length === 0) {
      setSnackbarMessage('Input prompt cannot be empty, Enter something!')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    } else {
      // alert('inside handleSendClick')
      setSendIsLoading(true)
      const uStatus: any = await userStatus(userId)
      if (uStatus?.status === false) {
        setSnackbarMessage('Something is wrong with user status!')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)
      } else {
        const promptValue = promptRef.current?.value ?? ''
        uStatus?.status && handleSearchCount(promptValue, userId)
      }
    }

    // setMessage('')
  }

  return (
    <>
      <Stack
        spacing={1}
        position='sticky'
        bottom={0}
        zIndex='1100'
        sx={{
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,0) 13.94%,#F8F8F8 54.73%)',
        }}
      >
        <Stack direction='row' alignItems='flex-end' flexGrow={1} spacing={2}>
          <Stack flexGrow={1}>
            <ResponseButton />
            {/* <InputMessage
            inputQuestion={inputQuestion}
            onChange={handleChange}
            onKeyDown={(event) => {
              handleEnterKeyPress(event, setInputQuestion)
            }}
          /> */}
            <StyledInputBase
              inputRef={promptRef}
              multiline
              maxRows={4}
              // value={inputQuestion}
              onChange={handleChange}
              onKeyDown={handleEnterKeyPress}
              placeholder='Enter a prompt here'
              endAdornment={isPaid === 'true' ? <MicPhoneButton /> : null}
            />
          </Stack>
          <Button
            variant='contained'
            disabled={promptRef?.current?.value?.length === 0}
            sx={{ minWidth: 50, width: 50, height: 50, p: 0 }}
            onClick={() => {
              handleSendClick() // Optionally, call handleSendClick as well
            }}
          >
            {sendIsLoading ? <CircularProgress color='warning' /> : <SendIcon />}
          </Button>
        </Stack>
        <Typography variant='link' fontWeight='regular'>
          <Typography variant='link' color='error.main'>
            *
          </Typography>{' '}
          This platform may produce inaccurate information, that doesn’t represent Law Tech views
        </Typography>
      </Stack>
      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert elevation={6} onClose={handleCloseSnackbar}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default SendMessage
