import React from 'react'
import { Box, Typography } from '@mui/material'

interface Props {
  small?: boolean
}

const Footer = ({ small }: Props) => {
  const bgColor = small ? 'common.white' : 'secondary.main'
  const defaultHeight = small ? 40 : 72
  const textColor = small ? 'text.primary' : 'text.warning'
  return (
    <Box
      component='footer'
      sx={{
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: bgColor,

        px: 2,
      }}
    >
      <Typography variant='subtitle1' color={textColor}>
        © {new Date().getFullYear()} LawTech. All rights reserved
      </Typography>
    </Box>
  )
}

export default Footer
