import React, { useEffect } from 'react'
import { useSidebarContext } from '../../../../../contexts'
import { Box } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

interface Props {
  message: any
}
interface JsonData {
  result: string
}

interface markDownData {
  markdown: string
}
const Message = ({ message }: Props) => {
  // alert('inside Message from src/pages/ChatBoat/MainContent/Messages/Message/index.tsx')
  const { allHistory } = useSidebarContext()
  const { setAnswer, messages, setIsLoading, simplifyText } = useSidebarContext()

  // console.log('allHistory', allHistory)
  // setIsLoading(false)

  // const renderFormattedText = (text: string) => {
  // }

  const MarkdownRenderer = ({ markdown }: markDownData) => {
    setIsLoading(false)
    return (
      <Box
        style={{
          width: '100%',
          overflow: 'auto',
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
      </Box>
    )
  }

  // return <div>{renderFormattedText(message)}</div>
  return <MarkdownRenderer markdown={message} />
}

export default Message
