import React, { type ChangeEvent, useState, useEffect } from 'react'
import {
  Avatar,
  Button,
  IconButton,
  InputBase,
  type InputBaseProps,
  Stack,
  Typography,
  styled,
  alpha,
  CircularProgress,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { EditIcon } from '../../../../components/Icons'
import { useSidebarContext } from '../../../../contexts'
import Stepper from '../Messages/Actions/Stepper'
import { toast } from 'react-toastify'
import baseUrl from '../../../../config/baseUrl'

interface Props {
  question: any
  index: any
  questionId: any
}

const StyledInputBase = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  borderWidth: 0.5,
  flexGrow: 1,
  height: 41,
  borderColor: alpha(theme.palette.common.black, 0.2),
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1.5),
  },
}))

const Question = ({ question, questionId, index }: Props) => {
  const [isEdit, setEdit] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(question)

  const {
    fetchServerResponse,
    isLoading,
    setIsLoading,
    userInput,
    editQuestionList,
    setQuestionId,
    setUserInput,
  } = useSidebarContext()

  useEffect(() => {
    setIsLoading(true)
  }, [])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    setUserInput(event.target.value) // Update the message state
  }

  const handleUpdate = () => {
    setIsLoading((prevState) => (prevState = true))
    setEdit(false)
    // onUpdate(inputValue)
    userStatus()
    setQuestionId(questionId)
  }

  const toggleClick = () => {
    setEdit(!isEdit)
  }

  const isPaid = sessionStorage.getItem('isPaid')

  const userId = localStorage.getItem('userId')

  const [countExceed, setCountExceed] = useState<boolean>(false)

  const handleSearchCount = async () => {
    try {
      const handleSearchCountResponse = await fetch(`${baseUrl}/api/users/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          UserId: userId,
        }),
      })

      const handleSearchCountResponseData = await handleSearchCountResponse.json()

      if (handleSearchCountResponse.ok) {
        fetchServerResponse()
      }

      if (!handleSearchCountResponse.ok) {
        toast.error(handleSearchCountResponseData.message)
        setIsLoading(false)
      }
    } catch (error: any) {
      console.log('Error in searchount api :', error.message)
    }
  }

  const token = localStorage.getItem('token')

  const userStatus = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/admin/checkuserstatus/${userId as string}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
        body: JSON.stringify({
          Promptquery: question,
        }),
      })

      const responseData = await response.json()

      if (responseData.status) {
        handleSearchCount()
      }
    } catch (error: any) {
      console.log('Error in userStatus API :', error.message)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 1000,
            // backgroundColor: 'rgba(202, 92, 92, 0.5)',
          }}
        >
          {/* <h3 style={{ color: 'green', position: 'fixed', top: '45%', left: '45%' }}>
            Updating...{' '}
          </h3> */}

          <CircularProgress
            disableShrink
            color='primary'
            thickness={4}
            style={{ position: 'fixed', top: '50%', left: '50%' }}
          />
        </div>
      )}
      <Stack spacing={1}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          p={2}
          spacing={3.5}
        >
          <Stack direction='row' alignItems='center' flexGrow={1} spacing={2}>
            <Avatar variant='square' sx={{ bgcolor: '#587DBD' }}>
              RT
            </Avatar>
            {!isEdit && (
              <Typography variant='subtitle2' color='textSecondary'>
                {question}
              </Typography>
            )}
            {isEdit && <StyledInputBase value={inputValue} onChange={handleChange} />}
          </Stack>
          {/* <Stepper /> */}
          <IconButton sx={{ p: 0.5, color: 'background.border' }} onClick={toggleClick}>
            <EditIcon />
          </IconButton>
        </Stack>
        {isEdit && (
          <Stack direction='row' spacing={3} justifyContent='flex-end'>
            <Button variant='outlined' sx={{ p: 0, width: 94, height: 40 }} onClick={toggleClick}>
              Cancel
            </Button>
            <LoadingButton
              variant='contained'
              sx={{ p: 0, width: 96, height: 40 }}
              onClick={() => {
                handleUpdate()
              }}
            >
              Update
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </div>
  )
}

export default Question
