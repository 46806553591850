import React from 'react'
import baseUrl from '../../../config/baseUrl'
import { ToastContainer, toast } from 'react-toastify'
import { Dialog, DialogContent, Typography, Stack, Button } from '@mui/material'
import { useSidebarContext } from '../../../contexts'
import { useNavigate } from 'react-router-dom'

interface Props {
  open: boolean
  title: string
  onConfirm: () => void
  onClose: () => void
  threadIdToDelete: any
}

const DeleteConfirmationDialog = ({ open, title, onClose, onConfirm, threadIdToDelete }: Props) => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  const { getUserHistory, setAllHistory, setIsThreadIdDelete, isThredIdDelete, threadId } =
    useSidebarContext()

  const deleteHistory = async (threadIdToDelete: any) => {
    try {
      const deleteResponse = await fetch(
        `${baseUrl}/api/users/DeleteHistorynew/${threadIdToDelete as string}`,
        {
          method: 'DELETE',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            authorization: token as string,
          },
        },
      )

      const deleteResponseData = await deleteResponse.json()

      if (deleteResponseData.status === true) {
        setIsThreadIdDelete(true)

        if (threadId === threadIdToDelete) {
          navigate('/NewChat')
          setAllHistory([])
        }
        getUserHistory(userId)
      }
    } catch (error: any) {
      console.log('error', error?.message)
      toast.warning(error?.message)
    }
  }

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: 456 } }}>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: '36px 48px',
        }}
      >
        <Stack spacing={3.5}>
          <Typography variant='subtitle2' align='center' color='textSecondary'>
            {title}
          </Typography>
          <Stack direction='row' justifyContent='center' spacing={2} width={1}>
            <Button variant='outlined' sx={{ p: 0, maxWidth: 102, height: 44 }} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='contained'
              sx={{ p: 0, maxWidth: 102, height: 44 }}
              onClick={() => {
                onConfirm()
                deleteHistory(threadIdToDelete)
              }}
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteConfirmationDialog
