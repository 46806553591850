import React, { type MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  type AvatarProps,
  IconButton,
  styled,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  type ListItemIconProps,
  type MenuItemProps,
} from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import Logout from '@mui/icons-material/Logout'
import { useAuthContext } from '../../contexts'

interface MenuItemTypes extends MenuItemProps {
  error?: boolean
}
interface ListItemIconTypes extends ListItemIconProps {
  error?: boolean
}
const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'error',
})<ListItemIconTypes>(({ theme, error }) => ({
  height: 20,
  color: error ? theme.palette.error.main : theme.palette.text.secondary,
}))

const StyledAvatar = styled(Avatar)<AvatarProps>(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: theme.palette.primary.light,
  fontSize: 14,
  fontWeight: 500,
}))

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'error',
})<MenuItemTypes>(({ theme, error }) => ({
  padding: '8px 6px',
  width: 188,
  fontSize: 14,
  fontWeight: 500,
  borderRadius: 4,
  color: error ? theme.palette.error.main : theme.palette.text.secondary,
}))

const ProfileMenu = () => {
  const navigate = useNavigate()
  const { setAuthenticated } = useAuthContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const firstName = sessionStorage.getItem('firstName')
  const lastName = sessionStorage.getItem('lastName')
  const firstLetter = firstName?.charAt(0).toUpperCase()
  const secondLetter = lastName?.charAt(0).toUpperCase()

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (routeName: string | null) => {
    if (routeName !== '/logout') {
      navigate(routeName as string)
    } else {
      setAuthenticated(false)
      navigate('/auth/login', { replace: true })
      localStorage.clear()
      sessionStorage.clear()
    }
    setAnchorEl(null)
  }
  return (
    <React.Fragment>
      <IconButton
        size='small'
        sx={{ p: 0 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <StyledAvatar>
          {firstLetter}
          {secondLetter}
        </StyledAvatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose(null)
        }}
        onClick={() => {
          handleClose(null)
        }}
        MenuListProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            p: 0,
          },
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              mt: 2,
              padding: '20px 14px',
              boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.08)',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <StyledMenuItem
          onClick={() => {
            handleClose('/faq/need-help-with-a-billing-issue')
          }}
        >
          <StyledListItemIcon>
            <HelpOutlineIcon fontSize='small' />
          </StyledListItemIcon>
          Help & FAQs
        </StyledMenuItem>

        <StyledMenuItem
          onClick={() => {
            handleClose('/settings')
          }}
        >
          <StyledListItemIcon>
            <SettingsOutlinedIcon fontSize='small' />
          </StyledListItemIcon>
          Settings
        </StyledMenuItem>
        <Divider />

        <StyledMenuItem
          error
          onClick={() => {
            handleClose('/logout')
          }}
        >
          <StyledListItemIcon error>
            <Logout fontSize='small' />
          </StyledListItemIcon>
          Logout
        </StyledMenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default ProfileMenu
