import React, { useState } from 'react'
import './rightDrawer.css'
import baseUrl from '../../../../config/baseUrl'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-quill/dist/quill.snow.css'
import 'quill/dist/quill.snow.css'

import {
  Alert,
  Grid,
  Snackbar,
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
  // TextField,
} from '@mui/material'

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'

const EditablePdfReader = (props) => {
  const { ans } = props
  const [file, setFile] = useState(null)

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  // Loading state
  const [isLoading, setIsLoading] = useState(false)

  const uId = sessionStorage.getItem('userId')

  const [userId, setUserId] = useState('')
  const [open, setOpen] = React.useState(false)

  const [anchor, setAnchor] = React.useState('right')

  const isSmallScreen = useMediaQuery('(max-width: 600px)')

  const [editorHtml, setEditorHtml] = useState('')

  React.useEffect(() => {
    setOpen(true)
    setFile(null)
    setEditorHtml(ans)
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      localStorage.setItem('editorHtml', editorHtml)
      event.returnValue = '' // Required for the prompt to show
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  React.useEffect(() => {
    setUserId(uId)
  }, [uId])

  React.useEffect(() => {
    if (file === 'testing') {
      file && uploadFile()
    }
  }, [file])

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const handlePrint = () => {
    const printWindow = window.open('', '_blank', 'width=900,height=700')

    // Write the quillContent to the new window
    printWindow.document.open()
    printWindow.document.write(`
      <html>
        <head>
          <title>LawTech</title>
          <style>
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${editorHtml}
        </body>
      </html>
    `)
    printWindow.document.close()

    // Trigger print
    printWindow.print()
    printWindow.close()
  }

  const uploadFile = async () => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('userid', userId)
    formData.append('editordocx', file)
    try {
      const response = await fetch(`${baseUrl}/api/editdocx/editdocxupload`, {
        method: 'POST',
        body: formData,
      })

      if (!response.ok) {
        setSnackbarMessage('Oops something went wrong while uploading file...')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)

        throw new Error('Network response was not ok')
      }

      const data = await response.json()

      if (data?.status) {
        setSnackbarMessage('File uploaded successfully')
        setSnackbarSeverity('success')
        setSnackbarOpen(true)
        readUploadedFile(data)
        setFile(null)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error uploading file:', error)
    }
  }

  const readUploadedFile = async (data) => {
    console.log('readUploadedFile data', data)
    try {
      const getReadFileResponse = await fetch(
        `${baseUrl}/api/editdocx/readEditDocx/${data.data.id}`,
      )
      if (!getReadFileResponse.ok) {
        setSnackbarMessage('Oops something went wrong while reading file...')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)
        throw new Error('something went wrong while reading file...')
      }

      const getReadFileData = await getReadFileResponse.json()
      console.log('getReadFileData', getReadFileData)

      if (getReadFileData?.status) {
        getReadFileData && setEditorHtml(getReadFileData?.data)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        throw new Error('Something went wrong while reading file...')
      }
    } catch (error) {
      setSnackbarMessage(error)
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
      setIsLoading(false)
    }
  }

  return (
    <>
      <Box position='relative'>
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              height: '100dvh',
              width: '100%',
              top: '50%',
              left: '45%',

              padding: '10px',
            }}
          >
            <CircularProgress color='success' />
          </Box>
        )}
        <React.Fragment key={anchor}>
          <Box
            open={true}
            onClose={!open}
            hideBackdrop={open}
            anchor={anchor}
            // border='6px solid #fb0039'
            PaperProps={{ sx: { width: '49%' } }}
            onClick={() => setAnchor('right')}
          >
            <div>
              <div
                style={{
                  backgroundColor: '#fbd439',
                  color: '#093f6b',
                }}
              >
                <h3 style={{ textAlign: 'center' }}>LawTech Editor at you hand...</h3>
              </div>
              <Grid container justifyContent='flex-end' marginBottom='10px'>
                <Button // button to upload file
                  startIcon={<LocalPrintshopIcon />}
                  variant='contained'
                  sx={{
                    height: isSmallScreen ? '30px' : '40px',
                    width: isSmallScreen ? '100px' : '140px',
                    marginRight: '10px',
                    marginTop: isSmallScreen ? '0px' : '10px',
                    color: 'black',
                    fontSize: isSmallScreen ? '10px' : '',
                  }}
                  onClick={handlePrint}
                >
                  Print
                </Button>
              </Grid>
              <div
                style={{
                  border: '1px solid red',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <ReactQuill
                  value={editorHtml}
                  onChange={setEditorHtml}
                  style={{
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                />
              </div>
            </div>
          </Box>
        </React.Fragment>
      </Box>

      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert elevation={6} onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

EditablePdfReader.propTypes = {
  ans: PropTypes.string.isRequired,
}
export default EditablePdfReader
