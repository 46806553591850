import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  Box,
  Typography,
  Button,
  Stack,
  Divider,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material'

import { AppLogoIcon, GoogleOutlinedIcon, LinkedinIcon } from '../../../components/Icons'

import RegisterForm from './RegisterForm'
import Categories from './Categories'
import { SuccessfullyDialog } from '../../../components/shared/Dialogs'
import { useAuthContext } from '../../../contexts/AuthContext'
import { ToastContainer, toast } from 'react-toastify'
import { useGoogleLogin } from '@react-oauth/google'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import EmailVerification from '../EmailVerification'
import baseUrl from '../../../config/baseUrl'
import SquareLogo from '../../../assets/images/Lawtech icon square.png'
const RegisterIndex = () => {
  const navigate = useNavigate()
  const { setAuthenticated } = useAuthContext()
  const [activeStep, setActiveStep] = useState<any>(0)
  const [successfullyDialog, setSuccessfullyDialog] = useState<any>(false)
  const [backotp, setBackotp] = useState<any>('')
  const [buttonClicked, setButtonClicked] = useState(false)
  const [registrationDetail, setRegistrationDetails] = useState<any>(null)

  const [userData, setUserData] = useState({ firstName: '', lastName: '', email: '', gid: '' })
  // const [googleData, setGoogleData] = useState([])

  const handleNextStep = () => {
    setActiveStep(1)
  }

  const handleEmailVerificationSuccess = () => {
    setActiveStep(2) // Move to the next step (step 2 of registration)
  }

  const googleRagister = async () => {
    try {
      const googleRagisterResponse = await fetch(`${baseUrl}/api/auths/googlesignup1`, {
        method: 'POST',
        mode: 'cors',

        // mode: 'no-cors',

        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          FirstName: userData.firstName,
          LastName: userData.lastName,
          Email: userData.email,
          GoogleId: userData.gid,
        }),
      })
      const googleRagisterResponseData = await googleRagisterResponse.json()

      if (googleRagisterResponseData.status === true) {
        handleNextStep()
        setUserData({
          firstName: '',
          lastName: '',
          email: '',
          gid: '',
        })

        sessionStorage.setItem('userId', googleRagisterResponseData?.user?.id)
        localStorage.setItem('userId', googleRagisterResponseData?.user?.id)

        setActiveStep(2)
      } else {
        console.log('something went wrong during signing')
      }
    } catch (error: any) {
      console.log('google register Error : ', error.message)
    }
  }

  const handleCompleted = (selectedValues: string) => {
    setSuccessfullyDialog(true)
  }

  const handleClose = () => {
    setSuccessfullyDialog(false)
    setAuthenticated(true)
    // navigate('/')
    navigate('/auth/login')
  }

  const signIn = useGoogleLogin({
    // clientId: '566567418175-e6d37md2ccqfcjs2hgptlu8p5sqr2v5m.apps.googleusercontent.com', // Your Google OAuth client ID

    onSuccess: async (response: any) => {
      // Handle the Google login response here
      // const data = await JSON.stringify(response)
      const googleToken = response.access_token

      const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${googleToken as string}`,
        },
      })

      const userInfoData = await userInfoResponse.json()

      if (userInfoResponse.ok) {
        setUserData({
          firstName: userInfoData.given_name,
          lastName: userInfoData.family_name,
          email: userInfoData.email,
          gid: userInfoData.id,
        })
      }
      sessionStorage.setItem('googleId', userInfoData.id)
    },
    onError: (error: any) => {
      // Handle login failure here
      console.error('Google login failed:', error)
      toast.error('Google login failed:', error)
    },
  })
  const signupGoogle = () => {
    signIn()
    setButtonClicked(true) // User clicked a button
  }

  useEffect(() => {
    // This useEffect will be triggered whenever `userData` changes.
    if (userData.firstName && userData.lastName && userData.email && userData.gid) {
      googleRagister() // Call googleRagister when `userData` is updated.
    }
  }, [userData])

  const { linkedInLogin } = useLinkedIn({
    clientId: '86vhj2q7ukf83q',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      // console.log(code)
    },
    onError: (error) => {
      console.log(error)
    },
  })

  return (
    <Grid item xs={7} sx={{ overflowY: 'auto', height: '100%', width: '100%' }}>
      <ToastContainer />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          py: 2,
          mt: 10,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            maxWidth: 644,
            m: '10px auto',
            gap: (!activeStep && 3) || 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            {/* <AppLogoIcon sx={{ width: 91, height: 63 }} /> */}
            <img src={SquareLogo} alt='' height='60px' />
            {activeStep !== 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant='h3'>Welcome!!</Typography>
                  {!activeStep && (
                    <Typography variant='body1'>Register to Law Tech Platform</Typography>
                  )}
                  {!!activeStep && (
                    <Typography variant='body1'>Select a Jurisdiction Category</Typography>
                  )}
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                      <StepLabel>Step 1</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>Step 2</StepLabel>
                    </Step>
                  </Stepper>
                </Box>
              </Box>
            )}
          </Box>
          {!activeStep && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                  width: '100%',
                }}
              >
                <Stack direction='row' spacing={3} width='100%'>
                  <Button
                    variant='contained'
                    sx={{
                      maxWidth: '100%',
                      backgroundColor: 'background.light',
                      color: 'text.secondary',
                      '&:hover, &:active, &:focus': {
                        backgroundColor: 'background.light',
                      },
                    }}
                    endIcon={<GoogleOutlinedIcon />}
                    onClick={signupGoogle}
                  >
                    Google
                  </Button>
                  <Button
                    onClick={linkedInLogin}
                    variant='contained'
                    sx={{
                      maxWidth: '100%',

                      backgroundColor: 'background.light',
                      color: 'text.secondary',
                      '&:hover, &:active, &:focus': {
                        backgroundColor: 'background.light',
                      },
                    }}
                    endIcon={<LinkedinIcon />}
                  >
                    LinkedIn
                  </Button>
                </Stack>
                <Divider sx={{ width: '80%' }}>
                  <Typography variant='subtitle1'>or continue with</Typography>
                </Divider>
              </Box>
              <Box sx={{ width: '100%' }}>
                <RegisterForm
                  setStep={setActiveStep}
                  setBackotp={setBackotp}
                  setRegistrationDetails={setRegistrationDetails}
                  registrationDetail={registrationDetail}
                />
              </Box>
            </>
          )}

          {/* {activeStep === 1 && (
              <EmailVerification onSuccess={handleEmailVerificationSuccess} />
            )} */}

          {!buttonClicked && activeStep === 1 && (
            <EmailVerification
              onSuccess={handleEmailVerificationSuccess}
              backotp={backotp}
              registrationDetail={registrationDetail}
            />
          )}
          {activeStep === 2 && (
            <Categories setDialog={setSuccessfullyDialog} />
            // Add components for step 2 (e.g., additional registration details)
          )}
        </Box>
      </Box>
      <SuccessfullyDialog open={successfullyDialog} onClose={handleClose} />
    </Grid>
  )
}

export default RegisterIndex
