import React from 'react'
import { Paper, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const faqData = [
  {
    question: 'Why Choose Law-Tech?',
    answer:
      'LawTech is an AI-based legal aid tool that offers several features for legal professionals and Law Students. It aims to revolutionize the legal field by providing a user-friendly, efficient, and cost-effective solution for legal research including Supreme Court/High Courts and various tribunals facts, judgments, etc., and document creation.',
  },
  {
    question: 'What subscription plans are included in LawTech?',
    answer:
      'LawTech provides 3 subscription plans: Basic, Premium, and Platinum. The basic plan offers essential features and limited access to legal prompts and case information. Users can choose this plan if they require basic functionalities. The Premium plan provides more comprehensive features, expanded access to legal prompts, and a wider range of case information. This plan is suitable for users who need a more advanced experience. The platinum plan offers the most extensive set of features, full access to all legal prompts and case database, and exclusive benefits. This top-tier plan is designed for users who require the highest level of service and functionality.',
  },

  {
    question: 'Does LawTech provides a legal advice? ',
    answer:
      'No, LawTech does not provide legal advice. We recommend taking an advice from professional legal consultant. The Content is not intended to be a substitute for professional legal advice. The Content provided on this website is solely for research purposes itself.',
  },

  {
    question: 'What does LawTech aims to?',
    answer:
      'LawTech aims to provide a depth legal research relating to various cases, judgments of Supreme Court and High Courts.',
  },

  {
    question: 'Can a Law Student use LawTech for research purpose?',
    answer: 'Yes, law students can use LawTech for moot research purpose as well.',
  },

  {
    question: 'How do I know a specific Supreme Court/ High Court Judgment on LawTech platform? ',
    answer:
      'You just need to give a prompt stating the name of the case and which judgment you are willing to know, whether its Supreme Court or High Court.',
  },

  {
    question: 'What information does LawTech provide on its AI platform?',
    answer: `LawTech aims to provide a wide range of information regarding the case which includes: 
A)	What are the issue in the case ABC.
B)	What are the facts in case ABC.
C)	What are the list of Citations and other references in case ABC.
D)	What are the legal Provisions or Sections included in the case ABC.
E)	Who are the Judges in the case ABC.
F)	Who are the Advocates or Legal Counsels in the case ABC.
G)	What is the Judgment or Decision or Findings of the Supreme Court of India/High Court/ Trial Court/Tribunals (if any) in case ABC.
H)	What is the Summary or background of the case ABC. `,
  },

  {
    question: 'Are Supreme Court Reports included on the LawTech website?',
    answer: 'Yes, Supreme Court Reports (SCR) are included on the LawTech website. ',
  },

  {
    question: 'How many year’s Supreme Court Reports are available on the LawTech Website?',
    answer:
      'Judgments from the year 1950 to 2024 (as of now) are included on the LawTech Website. ',
  },

  {
    question: 'How many volumes and Judgments are included in the Supreme Court Report? ',
    answer: 'A total of 519 volumes and 36,308 cases are included in Supreme Court Reports. ',
  },

  {
    question: 'What are the key features of Law-Tech?',
    answer:
      'The key feature of LawTech is that, it includes Supreme Court Reports (Judgments). A legal A.I tool which can be used for a legal research, drafting, judgments including, Civil, Criminal, DRT, Bankruptcy. Different prompts can be used for different task.',
  },
]

const Question = () => {
  return (
    <>
      <div>
        <h3>LawTech - Frequently Asked Questions</h3>
        {faqData.map((item) => (
          <Accordion key={item.question}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${item.question}`}
              id={`panel${item.question}-header`}
            >
              <Typography variant='subtitle1' fontWeight={500}>
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ m: 0, pt: 0 }}>
              <Typography align='justify' variant='subtitle1' style={{ padding: '0px 10px' }}>
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderLeft: '3px solid',
          borderColor: 'primary.main',
          boxShadow: 'none',
          p: '24px 36px',
          gap: 2,
        }}
      >
        <Typography variant='caption' fontWeight={500} color='textSecondary'>
          Need help with a billing issue
        </Typography>
      </Paper>
    </>
  )
}

export default Question
