import React, { type ReactElement } from 'react'
import { Box, Typography, alpha, useTheme } from '@mui/material'

interface Props {
  name: string
  selected: string
  CategoryIcon: ReactElement
  onClick: (name: string) => void
}

const Category = ({ name, selected, CategoryIcon, onClick }: Props) => {
  const { palette } = useTheme()
  const hasSelected = name === selected
  const bgColor = hasSelected ? alpha(palette.primary.main, 0.1) : null
  const borderColor = hasSelected ? palette.primary.main : 'rgba(0, 0, 0, 0.2)'
  const iconBgColor = hasSelected ? alpha(palette.primary.main, 0.5) : palette.background.dark
  const strokeColor = hasSelected ? palette.text.secondary : palette.text.primary
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        maxHeight: 238,
        minHeight: 238,
        height: '100%',
        maxWidth: 194,
        borderWidth: 0.5,
        borderStyle: 'solid',
        borderColor,
        borderRadius: 1,
        position: 'relative',
        cursor: 'pointer',
        gap: 3,
        bgcolor: bgColor,
      }}
      onClick={() => {
        onClick(name)
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 24,
          height: 24,
          position: 'absolute',
          top: 12,
          right: 12,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'background.border',
            borderRadius: '100%',
            width: 20,
            height: 20,
          }}
        >
          {hasSelected && (
            <Box sx={{ width: 10, height: 10, bgcolor: 'primary.main', borderRadius: '100%' }} />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 36,
          height: 36,
          bgcolor: iconBgColor,
          borderRadius: 1,
        }}
      >
        {React.cloneElement(CategoryIcon, {
          sx: { fill: 'none', stroke: strokeColor },
        })}
      </Box>
      <Typography
        variant='caption'
        align='center'
        sx={{
          width: 'min-content',
          ml: 'auto',
          mr: 'auto',
        }}
      >
        {name}
      </Typography>
    </Box>
  )
}

export default Category
