import React, { useContext } from 'react'
import {
  AppBar,
  useMediaQuery,
  type Breakpoint,
  Container,
  Toolbar,
  Grid,
  Box,
  IconButton,
} from '@mui/material'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import { AppLogoIcon } from '../Icons'
import NavLinks from './NavLinks'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import LawTechLogo from '../../assets/images/Law tech logo with patch.png'
import SContext from '../../contexts/SidebarContext'
import filejson from '../../assets/files/central all json file.json'
interface Props {
  maxWidth: Breakpoint
  hideHeader?: boolean
}

const Header = ({ maxWidth, hideHeader }: Props) => {
  const theme = useTheme()
  const xsMatched = useMediaQuery(theme.breakpoints.down('xs'))
  const smMatched = useMediaQuery(theme.breakpoints.down('sm'))
  const mdMatched = useMediaQuery(theme.breakpoints.down('md'))

  const token = localStorage.getItem('token')

  React.useEffect(() => {
    fetch('../../assets/files/central all json file.json')
      .then((response) => response.json())
      .then((data) => {
        // Process the JSON data here
        console.log('header', data)
      })
      .catch((error) => {
        console.error('Error fetching JSON:', error)
      })
  }, [])
  // if (hideHeader) {
  //   return null
  // }
  return (
    <AppBar style={{ zIndex: 10002 }} position='fixed' color='default' component='nav'>
      <Container maxWidth={maxWidth}>
        <Toolbar>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {token == null && (
                  <Link to='/'>
                    <img src={LawTechLogo} alt='' height='80px' />
                    {/* <AppLogoIcon sx={{ width: 91, height: 62 }} /> */}
                  </Link>
                )}

                {token !== null && (
                  <Link to='/newChat'>
                    <img src={LawTechLogo} alt='' height='80px' />
                    {/* <AppLogoIcon sx={{ width: 91, height: 62 }} /> */}
                  </Link>
                )}

                {/* {token !== null && (
                  <Link to='/verifier'>
                    <FactCheckIcon sx={{ zIndex: 10002, color: 'green', fontSize: 40 }} />
                  </Link>
                )} */}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Box
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: mdMatched ? 'center' : 'flex-end',
                }}
              >
                <NavLinks />
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header
