import React, { useState, useEffect } from 'react'
import baseUrl from '../../config/baseUrl'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'
import SContext from '../../contexts/SidebarContext'
import OldSidebarContext from '../../contexts/Old_SidebarContext'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {
  Box,
  IconButton,
  Drawer,
  List,
  TextField,
  InputAdornment,
  Typography,
  ListItemButton,
  CircularProgress,
  ListItemText,
  Grid,
  Tooltip,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import EditNoteIcon from '@mui/icons-material/EditNote'

const menus = [
  {
    title: 'Acts',
    subMenus: [
      'Section 2 of The Academy Of Scientific And Innovative Research Act, 2011',
      'Section 5 of The Administrative Tribunals Act, 1985',
      'Section 13 of The Actuaries Act, 2006',
      'Section 2 of The Acquisition of Certain Area At Ayodhya Act, 1993',
      'Section 14 of The Academy Of Scientific And Innovative Research Act, 2011',
      'Section 20 of the Broach and Kaira Incumbered Estates Act, 1877',
      'Section 12 of the Building and other Construction Workers (Regulation of Employment and Conditions of Service)Act, 1996',
      'Section 3 of the Calcutta High Court (Extension of Jurisdiction) Act, 1953',
      'Section 9 of The Company Secretaries Act, 1980',
      'Section 26 of The Commission for Air Quality Management in National Capital Region and Adjoining Areas Act, 2021',
      'Section 15 of the Coinage Act, 2011',
      'Section 26 of THE COASTAL AQUACUL TURE AUTHORITY ACT, 2005',
      'Section 4 of The Press Council Act, 1978',
      'Section 3 of Prevention of Terrorism Repeal Act, 2004',
    ],
  },
  {
    title: 'Judgements',
    subMenus: ['1', '2', '3'],
  },
  {
    title: 'Formats',
    subMenus: [
      'Format of the Notice Landlord to Tenant for Eviction',
      'Format of the Notice for Breach of Leave and Licence Agreement',
      'Format of the Notice for Compensation for Defect in Goods',
      'Format of the Notice for Dishonour of Cheque',
      'Format of the Notice for Expulsion of a Partner or Notice under section 33 of Indian Partnership Act, 1932',
      'Format of the Notice for Insurance Claim',
      'Format of the Notice to Terminate Contract',
      'Format of the Notice for Trespass and Damage to Property',
      'Format of the Notice to Refrain from Acting as Director of the Company',
      'Format of the Notice to Claim Maintenance',
      'Format of reply notice to notice for eviction',
      'Format of the Notice for Recovery of Money and Appointment of Arbitrator',
      'Format Application for grant of probate.',
      'Format for Petition for Dissolution of Marriage',
      'Format of Suit for Medical Negligence',
    ],
  },
]

function Verifier() {
  // const userId = sessionStorage.getItem('userId')
  const anchor = 'left'
  const { setOpenVerifier } = React.useContext(SContext)
  const [state, setState] = React.useState({
    left: false,
  })
  const [loader, setLoader] = useState(false)
  const [openMenu, setOpenMenu] = useState(null)
  const [verifierAnswer, setVerifierAnswer] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')

  const navigate = useNavigate()

  const { answer } = React.useContext(OldSidebarContext)
  // const { lastAskedQuestion, answer, userStatus, handleSearchCount } =
  //   React.useContext(OldSidebarContext)
  useEffect(() => {
    setLoader(false)
    answer && setVerifierAnswer(answer)
  }, [answer])

  function parseQueryString() {
    const queryString = window.location.search.substring(1)
    // console.log('queryString', queryString)
    const pairs = queryString.split('&')
    // console.log('pairs', decodeURIComponent(pairs))
    const parsed = {}

    for (let i = 0; i < pairs.length; i++) {
      const [key, value] = pairs[i].split('=')
      parsed[decodeURIComponent(key)] = decodeURIComponent(value)
    }

    // console.log('parsed', parsed)
    return parsed
  }

  const queryParams = parseQueryString()
  // console.log('queryParams', queryParams)

  const objectFromQuery = queryParams
  // console.log('objectFromQuery', objectFromQuery)

  const handleClick = (index) => {
    setOpenMenu(openMenu === index ? null : index)
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase())
  }

  // const filteredMenus = menus.filter(
  //   (menu) =>
  //     menu.title.toLowerCase().includes(searchQuery) ||
  //     menu.subMenus.some((subMenu) => subMenu.toLowerCase().includes(searchQuery)),
  // )

  const handleSubMenuClick = async (subMenu) => {
    // console.log('Hello World', subMenu)
    setLoader(true)

    try {
      const response = await fetch(`${baseUrl}/api/admin/getsection`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sectiontype: subMenu,
        }),
      })
      console.log('verifier response', response)
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`)
      }

      const json = await response.json()
      console.log('verifier json ', json)
      setVerifierAnswer(json?.data)
      setLoader(false)
      setState({ left: false })
    } catch (error) {
      setLoader(false)
      setVerifierAnswer(null)
      console.log('error', error)

      console.log('verifierAnswer', verifierAnswer)
    }
  }

  const filteredMenus = menus
    .map((menu) => ({
      ...menu,
      subMenus: menu.subMenus.filter((subMenu) => subMenu.toLowerCase().includes(searchQuery)),
    }))
    .filter((menu) => menu.subMenus.length > 0)

  const toggleDrawer =
    (anchor = 'left', open = true) =>
    (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }
      if (open === false) {
        setOpenVerifier(false)
      }
      setState({ ...state, [anchor]: open })
    }

  const MarkdownRenderer = (props) => {
    const { textToDisplay } = props
    // console.log('message', message)

    return (
      <Box
        style={{
          minHeight: '100%',
          width: '100%',
          overflow: 'auto',
          textAlign: 'justify',
          padding: '10px',
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{textToDisplay}</ReactMarkdown>
      </Box>
    )
  }

  MarkdownRenderer.propTypes = {
    textToDisplay: PropTypes.string.isRequired,
  }

  return (
    <Box
      style={{
        minHeight: '100%',
        minWidth: '100%',
        position: 'relative',
        backgroundColor: '#fafafa',
      }}
    >
      {loader && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: '10001',
          }}
        >
          <h1>Loading ...</h1>
          <CircularProgress />
        </Box>
      )}

      <IconButton
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          color: '#278f42',
          width: '32px',
          height: '32px',
        }}
        aria-label='close'
        onClick={toggleDrawer(anchor, true)}
      >
        <MenuIcon />
      </IconButton>

      <Box>
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            // open={true}
            style={{ position: 'absolute', left: '0px', top: '10px' }}
            onClose={toggleDrawer(anchor, false)}
          >
            {/*  close drawer button */}
            <Box
              style={{
                padding: '5px 15px',
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <Tooltip title='Home' placement='right'>
                <IconButton
                  style={{ width: '32px', height: '32px' }}
                  aria-label='close'
                  onClick={() => {
                    navigate(objectFromQuery?.hId ? `/NewChat/${objectFromQuery?.hId}` : '/NewChat')
                  }}
                >
                  <HomeIcon
                    style={{ color: '#278f42', width: '32px', height: '32px' }}
                    aria-label='home'
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title='Close' placement='left'>
                <IconButton
                  style={{ color: '#ff0000', width: '32px', height: '32px' }}
                  aria-label='close'
                  onClick={() => {
                    navigate(objectFromQuery?.hId ? `/editor/${objectFromQuery?.hId}` : '/editor')
                  }}
                >
                  <EditNoteIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title='Close' placement='left'>
                <IconButton
                  style={{ color: '#ff0000', width: '32px', height: '32px' }}
                  aria-label='close'
                  onClick={toggleDrawer(anchor, false)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <TextField
              variant='outlined'
              placeholder='Search...'
              fullWidth
              margin='normal'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <List
              sx={{ width: '100%', minWidth: 335, maxWidth: 335, bgcolor: 'background.paper' }}
              component='nav'
              aria-labelledby='nested-list-subheader'
            >
              {filteredMenus.map((menu, index) => (
                <React.Fragment key={menu.title}>
                  <ListItemButton onClick={() => handleClick(index)}>
                    <ListItemText primary={menu.title} />
                    {openMenu === index ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openMenu === index} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      {menu.subMenus.map((subMenu) => (
                        <ListItemButton
                          sx={{ pl: 4 }}
                          key={subMenu}
                          onClick={() => handleSubMenuClick(subMenu)}
                        >
                          <ListItemText primary={subMenu} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Drawer>
        </React.Fragment>
      </Box>

      <Grid container spacing={1}>
        <Grid xs={12} md={6}>
          <Box
            style={{
              // backgroundColor: '#f4f4f4',
              height: '100%',
              minWidth: '49%',
              // marginTop: '90px',
            }}
          >
            <Box sx={{ pt: 2, px: 1 }}>
              <Typography textAlign={'center'} style={{ fontWeight: 'bold', color: 'navy' }}>
                Verifier
              </Typography>
              <hr />

              {/* Left Drawer for search and Acts/ Judtements/ Formats */}

              <MarkdownRenderer textToDisplay={verifierAnswer} />
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={6}>
          <Box
            style={{
              // backgroundColor: '#f4f4f4',
              minHeight: '100%',
              minwidth: '49%',

              zIndex: '10000',
            }}
          >
            <Box sx={{ pt: 2, px: 1 }}>
              <Typography textAlign={'center'} style={{ fontWeight: 'bold', color: 'navy' }}>
                AI Response
              </Typography>
              <hr />
              <Typography variant='h6' color='navy'>
                Question
              </Typography>
              <MarkdownRenderer textToDisplay={objectFromQuery?.qs} />
              <Typography variant='h6' color='green'>
                Answer
              </Typography>
              <MarkdownRenderer textToDisplay={objectFromQuery?.ans} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Last Asked and answered queston */}
    </Box>
  )
}

export default Verifier
