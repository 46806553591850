import React, { useEffect, useRef, useState } from 'react'

import { Box, Button, Grid, IconButton } from '@mui/material'
import TextField from '../../../components/shared/TextField'
import { EditIcon } from '../../../components/Icons'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import baseUrl from '../../../config/baseUrl'

interface Props {
  phoneNumber: any
  token: any
  userId: any
}
const ContactNumber = ({ phoneNumber, token, userId }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [contactNumber, setContactNumber] = useState<any>(phoneNumber || '')
  const [editable, setEditable] = useState<boolean>(true) // false

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const currentValue = event.currentTarget.value
    setContactNumber(currentValue.trim())
  }

  useEffect(() => {
    setContactNumber(phoneNumber)
  }, [phoneNumber])

  const handleClick = () => {
    setEditable(false)
    if (inputRef.current) {
      inputRef.current?.focus()
    }
  }

  const handleSave = async () => {
    // setContactNumber('')
    setEditable(true)
    try {
      if (!contactNumber) {
        toast.error('Enter Contact Number')
        setEditable(false)
      }
      if (contactNumber.length > 10 || contactNumber.length < 10) {
        toast.error('Please Enter Valid Contact Number')
        setEditable(false)
      }

      const response = await fetch(`${baseUrl}/api/users/ProfileUpdate1/${userId as string} `, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
        body: JSON.stringify({ PhoneNumber: contactNumber }),
      })

      const data = await response.json()

      if (data.status) {
        sessionStorage.setItem('phone', data.user.PhoneNumber)
        toast.success(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const checkLengthValue = contactNumber?.length > 0

  return (
    <>
      <Grid item xs={6} md={6}>
        <TextField
          inputRef={inputRef}
          label='Contact No.'
          placeholder='Enter Contact No.'
          name='contactNumber'
          value={contactNumber}
          onChange={handleChange}
          disabled={editable}
          type='number'
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <Box display='flex' width={1} height={1} alignItems='center'>
          {/* {editable && checkLengthValue && (
            <IconButton sx={{ mt: 3.5, p: 1, color: 'primary.main' }} onClick={handleClick}>
              <EditIcon />
            </IconButton>
          )}
          {(!checkLengthValue || !(editable && !checkLengthValue)) && (
            <Button
              variant='outlined'
              sx={{ mt: 3.5, maxWidth: 71, height: 37, p: 0 }}
              onClick={handleSave}
            >
              Save
            </Button>
          )} */}
          {editable && (
            <IconButton sx={{ mt: 3.5, p: 1, color: 'primary.main' }} onClick={handleClick}>
              <EditIcon />
            </IconButton>
          )}
          {!editable && (
            <Button
              variant='outlined'
              sx={{ mt: 3.5, maxWidth: 71, height: 37, p: 0 }}
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </Box>
      </Grid>
    </>
  )
}

export default ContactNumber
