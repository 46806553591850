import { Box, Container, Stack } from '@mui/material'
import React, { useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Breadcrumbs from '../../components/shared/Breadcrumbs'
import LeftSidebar from './LeftSidebar'
import ProfileSetting from './ProfileSetting'
import DataSetting from './DataSetting'
import TransactionHistory from './TransactionHistory/TransactionHistory'
import { SidebarProvider } from '../../contexts'

const SettingIndex = () => {
  const [selectedTab, setSelectedTab] = useState<number>(1)

  const handleClick = (selected: number) => {
    setSelectedTab(selected)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 12,
        bgcolor: 'common.white',
      }}
    >
      <Header maxWidth='false' />
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 136px)',
        }}
      >
        <SidebarProvider>
          <Stack flexGrow={1} bgcolor='background.dark' px={3.5} py={3} spacing={3.5}>
            <Breadcrumbs />
            <Stack direction='row' flexGrow={1} spacing={3} width={1}>
              <LeftSidebar selected={selectedTab} onClick={handleClick} />
              <Stack
                bgcolor='common.white'
                p={2}
                borderRadius={1}
                spacing={3}
                width='calc(100% - 260px)'
              >
                {selectedTab === 1 && <ProfileSetting />}
                {selectedTab === 2 && <DataSetting />}
                {selectedTab === 3 && <TransactionHistory />}
              </Stack>
            </Stack>
          </Stack>
        </SidebarProvider>
      </Container>
      <Footer small />
    </Box>
  )
}

export default SettingIndex
