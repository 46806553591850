import React, { useContext, createContext, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import baseUrl, { pythonUrl } from '../config/baseUrl'
import { ToastContainer, toast } from 'react-toastify'

interface SidebarContextValue {
  sendAnswerToLeftDrawer: string
  setSendAnswerToLeftDrawer: React.Dispatch<React.SetStateAction<string>>

  simplifyText: (text: any) => string
  selectedTab: number
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>

  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  lastAskedQuestion: string
  setLastAskedQuestion: React.Dispatch<React.SetStateAction<string>>
  lastAnsweredQuestion: string
  setLastAnsweredQuestion: React.Dispatch<React.SetStateAction<string>>
  globalTokenConsumed: any
  setGlobalTokenConsumed: React.Dispatch<React.SetStateAction<any>>
  sidebarOpen: boolean
  setSidebar: React.Dispatch<React.SetStateAction<boolean>>
  aiStorage: any[]
  setAiStorage: React.Dispatch<React.SetStateAction<any[]>>
  inputMessage: string
  setInputMessage: React.Dispatch<React.SetStateAction<string>>
  answer: string
  setAnswer: React.Dispatch<React.SetStateAction<string>>

  userStatus: (userId: string) => Promise<any>
  handleSearchCount: (inputQuestion: string, userId: any) => Promise<void>
  sendData: (inputQuestion: string) => Promise<void>
  getHistory: (userId: string) => Promise<any>

  message: string // Add message property to the interface
  setMessage: React.Dispatch<React.SetStateAction<string>> // Add setMessage property to the interface
  getUserHistory: (userId: any) => Promise<void>
  userHistory: any[]
  setUserHistory: React.Dispatch<React.SetStateAction<any[]>>
  editHistoryTItleAPi: (title: any, historyId: any) => Promise<void>

  setIsResponseComplete: React.Dispatch<React.SetStateAction<boolean>>
  allHistory: any[]
  setAllHistory: React.Dispatch<React.SetStateAction<any[]>>
  threadId: any // Update the type to allow null values
  setThreadId: React.Dispatch<React.SetStateAction<any>> // Add setMessage property to the interface
  clearAllHistory: () => Promise<void>

  buttonsDisabled: boolean
  setButtonsDisabled: React.Dispatch<React.SetStateAction<boolean>>

  // Message Context Functionality
  messages: string[]
  activeMessageContext: number
  setActiveMessageContext: React.Dispatch<React.SetStateAction<number>>

  // Search History Functionality

  searchValue: any
  setSearchValue: React.Dispatch<React.SetStateAction<any>>

  // Edit Question
  fetchServerResponse: () => Promise<void>
  editQuestionList: any[]
  setQuestionId: any
  userInput: any
  setUserInput: React.Dispatch<React.SetStateAction<string>> // Corrected property name

  // thredId Deleted

  isThredIdDelete: boolean
  setIsThreadIdDelete: React.Dispatch<React.SetStateAction<boolean>>
}

const messages = ['']

interface Props {
  children: React.ReactNode
}

const SidebarContext = createContext<SidebarContextValue | undefined>(undefined)

export const SidebarProvider = ({ children }: Props) => {
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('userId')

  const [selectedTab, setSelectedTab] = useState<number>(1)

  const [sidebarOpen, setSidebar] = useState<boolean>(true)
  const [aiStorage, setAiStorage] = useState<any[]>([])
  const [inputMessage, setInputMessage] = useState('')
  const [answer, setAnswer] = useState('')
  const [message, setMessage] = useState<string>('')

  const [responseData, setResponseData] = useState('')
  const [tokenConsumed, setTokenConsumed] = useState<string | null>(null)

  const [threadId, setThreadId] = useState<string>('') // Initialize with null

  const [userHistory, setUserHistory] = useState<any[]>([])
  const [allHistory, setAllHistory] = useState<any[]>([])

  const [isResponseComplete, setIsResponseComplete] = useState<boolean>(false) // Add this flag

  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false)

  const [isThredIdDelete, setIsThreadIdDelete] = useState<boolean>(false)

  // Message Context

  const [activeMessageContext, setActiveMessageContext] = useState<number>(0)

  // Search Histroy
  const [searchValue, setSearchValue] = useState<any>('') // State to store user input

  // for token consumption count

  const [globalTokenConsumed, setGlobalTokenConsumed] = useState<any>(null)
  const [lastAskedQuestion, setLastAskedQuestion] = useState('')
  const [lastAnsweredQuestion, setLastAnsweredQuestion] = useState('')
  // For Pagination

  const [userInput, setUserInput] = useState('')
  const [responseComplete, setResponseComplete] = useState(false)
  const [editQuestion, setEditQuestion] = useState('')
  const [serverResponse, setServerResponse] = useState('')
  const [questionId, setQuestionId] = useState<any>(null)
  const [editQuestionList, setEditQuestionList] = useState<any[]>([])

  // loader
  const [isLoading, setIsLoading] = useState(false)
  const [sendAnswerToLeftDrawer, setSendAnswerToLeftDrawer] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    getUserHistory(userId)
  }, [])

  useEffect(() => {
    // console.log('inside sidebar sendAnswerToLeftDrawer', sendAnswerToLeftDrawer)
  }, [sendAnswerToLeftDrawer])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    // alert(`is loading changed to ${isLoading}`)
  }, [isLoading])

  useEffect(() => {
    if (threadId !== '' && threadId !== null && threadId !== undefined) {
      getUserHistorybyThreadId(threadId)
    }
  }, [threadId, aiStorage, questionId])

  useEffect(() => {
    if (isThredIdDelete) {
      threadId !== '' &&
        threadId !== null &&
        threadId !== undefined &&
        getUserHistorybyThreadId(threadId)
    }
    setIsResponseComplete(false)
  }, [isThredIdDelete])

  useEffect(() => {
    // if (isResponseComplete) {
    //   postHistory()
    // }
    setIsResponseComplete(false)
  }, [isResponseComplete])

  useEffect(() => {
    if (responseComplete) {
      storeEditedHistory()
    }

    setResponseComplete(false)
  }, [responseComplete])

  const userStatus = async (userId: string) => {
    // setMessage(inputQuestion)
    // alert('inside userStatus')

    try {
      const response = await fetch(`${baseUrl}/api/admin/checkuserstatus/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
        body: JSON.stringify({
          Promptquery: message,
        }),
      })

      const responseData = await response.json()
      // console.log('userStatus responseData :', responseData)
      return responseData
      // responseData?.status && handleSearchCount()
    } catch (error: any) {
      setIsLoading(false)
      console.log('Error in userStatus API :', error.message)
      return error
    }
  }

  const handleSearchCount = async (inputQuestion: string, userId: any) => {
    // alert('inside handleSearchCount')
    try {
      const handleSearchCountResponse = await fetch(`${baseUrl}/api/users/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          UserId: userId,
        }),
      })

      const handleSearchCountResponseData = await handleSearchCountResponse.json()
      // console.log('handleSearchCountResponse', handleSearchCountResponse)

      if (handleSearchCountResponse?.ok) {
        setIsLoading(false)
        // console.log('inputQuestion: string, before sendmsg', inputQuestion)
        inputQuestion && sendData(inputQuestion)
      }
    } catch (error: any) {
      console.log('Error in searchount api :', error.message)
    }
  }

  const sendData = async (inputQuestion: string) => {
    // alert('inside sendData')
    // let chunks = ''
    let modifiedString = ''

    if (inputQuestion) {
      try {
        setLastAskedQuestion(inputQuestion)
        // setInputMessage('')

        const response = await fetch(`${pythonUrl}/pyapi/search`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ Promptquery: inputQuestion }),
        })

        if (!response.ok) {
          throw new Error('Request failed')
        }

        const responseData = await response.json()

        if (responseData?.result) {
          // const reader = response.body.getReader()
          // console.log('responseData', responseData)

          // while (true) {
          //   const { done, value } = await reader.read()
          //   if (done) {
          //     setIsResponseComplete(true)

          //     break
          //   }
          //   chunks += new TextDecoder().decode(value)
          // }

          // const match = chunks.match(/total_tokens_consumed":(\d+)/)
          // const totalTokensConsumed = match ? match[1] : null
          setTokenConsumed(responseData?.total_tokens_consumed)
          // setGlobalTokenConsumed(totalTokensConsumed)
          // console.log('chunks', chunks)
          setResponseData(responseData?.result)

          // const data = await response.json()
          // console.log('data doubtful', data)

          modifiedString = simplifyText(responseData?.result)

          // modifiedString && console.log('modifiedString12', modifiedString)

          modifiedString && setMessage(modifiedString)
          modifiedString && setAnswer(modifiedString)
          modifiedString && setLastAnsweredQuestion(modifiedString)

          postHistory(
            inputQuestion,
            modifiedString,
            userId,
            threadId,
            responseData?.total_tokens_consumed,
          )
          modifiedString = ''
          setIsLoading(false)
        }
      } catch (error) {
        console.error('An error occurred while submitting the form data', error)
      }
    }
  }

  const getHistory = async (userId: string) => {
    try {
      const response = await fetch(`${baseUrl}/api/users/FindHistoryAll/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
      })

      if (!response.ok) {
        throw new Error(`Failed to fetch user history: ${response.statusText}`)
      }

      const { data } = await response.json()
      const last10 = data.slice(0, 6)
      // console.log('getHistory data', last10)
      return last10
    } catch (error) {
      console.error('Error fetching user history:', error)
    }
  }

  const simplifyText = (text: string) => {
    const mapObj: Record<string, string> = {
      '\\n': '<br />',
      '{"result":"': '',
      '","total_tokens_consumed":2589}': '',
    }

    const initialText = text

    // console.log('initialText', initialText)

    const formattedText = initialText?.replace(
      /{"result":"|","total_tokens_consumed":2589}/gi,
      (matched: string): string => {
        return mapObj[matched] || matched
      },
    )

    // console.log('formattedText', formattedText)

    let modifiedString = ''

    modifiedString = formattedText?.replace('}', '')

    // modifiedString = formattedText?.replace('#', '<h1>')

    modifiedString = modifiedString?.replace('{"result":"', '')

    // console.log('modifiedString result', modifiedString)
    modifiedString = modifiedString?.replace('","total_tokens_consumed":', '')

    modifiedString = modifiedString?.replace(/\\u201c/g, ' "')

    // console.log('modifiedString /\\u201c/g', modifiedString)

    modifiedString = modifiedString?.replace(/\\u201d/g, '"')

    modifiedString = modifiedString?.replace(/\\u00b7/g, '&middot;')

    // console.log('modifiedString /\\u00b7/g', modifiedString)

    // modifiedString = modifiedString?.replace(/\\"/g, '"')

    // // modifiedString = modifiedString?.replace(/\*\*/, '<b>').replace(/\*\*/, '</b>')

    modifiedString = modifiedString?.replace(/\s*\d+\s*$/, '')

    // modifiedString = modifiedString
    //   ?.replace(/([^.\n])\\n\\n([^.\n])/g, '$1 $2')
    //   ?.replace(/\\n\\n/g, '\n')

    // console.log('modifiedString /s*d+s*$/', modifiedString)

    modifiedString = modifiedString?.replace(/\\n/g, '\n')

    modifiedString = modifiedString?.replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')

    // console.log('modifiedString /\\t/g', modifiedString)

    modifiedString = modifiedString.replace(/\((\d+)\)/g, (match, number: string) => {
      if (Number(number) < 50) {
        return `\n(${number})` // Move captured number to the next line
      }
      return match // Keep other matches unchanged
    })

    // modifiedString = modifiedString
    //   ?.replace(/\((\w)\)/g, '\n($1)')
    //   ?.replace(/(?<!\S)(To,)(?!\S)/gi, '\n$1')
    //   ?.replace(/(_{10,})/g, '\n$1')
    //   ?.replace(/(?<!\S)(Sir,)(?!\S)/gi, '\n$1')
    //   ?.replace(/(?<!\S)(^.\d.)(?!\S)/gi, '\n$1')
    //   ?.replace(/(From: .+)/g, '\n$1\n')
    //   ?.replace(/(Date: .+)/g, '\n$1\n')
    //   ?.replace(/\s(\d+\.)/g, '\n$1')
    //   ?.replace(/\*\*([^*]+)\*\*/g, '<b>$1</b>')

    // modifiedString = modifiedString
    //   ?.replace(/\*\*(.*?)\*\*|\b\*\*(.*?)\*\*\b/, '<b>$1</b>')
    //   .replace(/([^.])(\\n\\n)/g, '')

    // const pattern = /\b\d+\.\s(?:[^.]*?(?<!\.)\s(?!.*?\.)|[^.\n])*?:/g

    // modifiedString = modifiedString.replace(pattern, '<b>$&</b>')
    // // modifiedString = String.fromCharCode(parseInt(modifiedString.substring(2), 16))

    modifiedString = decodeUnicode(modifiedString)
    // console.log('modifiedString', modifiedString)

    return modifiedString
    // return formattedText
  }

  const decodeUnicode = (str: string) => {
    return str.replace(/\\u[\dA-F]{4}/gi, (match: string) => {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
    })
  }

  const getUserHistorybyThreadId = async (tId: string) => {
    let modifiedString = ''
    try {
      const userHistoryRespo = await fetch(`${baseUrl}/api/users/gethistory/${tId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
      })

      const userHistoryData = await userHistoryRespo.json()

      // setAllHistory(userHistoryData?.historydata)

      modifiedString = simplifyText(userHistoryData[0]?.SearchResult)

      userHistoryData[0].SearchResult = modifiedString

      setAllHistory(userHistoryData)
      // setIsLoading(false)
    } catch (error: any) {
      console.log('This Error occur in fetching history ', error.message)
    }
  }

  // Function 2

  const postHistory = async (
    searchTitle: string,
    searchResult: string,
    userId: any,
    threadId: string,
    tokenConsumed: any,
  ) => {
    try {
      const postResponse = await fetch(`${baseUrl}/api/users/StoreHistory1`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,

          key: 'Cross-Origin-Opener-Policy',
          value: 'same-origin allow-popups',
        },
        body: JSON.stringify({
          SearchTitle: searchTitle,
          SearchResult: searchResult,
          UserId: userId,
          ThreadId: threadId,
          Token: tokenConsumed,
        }),
      })

      const postHistoryData = await postResponse.json()
      // console.log('postHistoryData', postHistoryData)
      if (postHistoryData.status === true) {
        const data = postHistoryData?.data
        setThreadId((prevId) => (prevId = data?.ThreadId))
        getUserHistory(userId)
        getUserHistorybyThreadId(data?.ThreadId)
        // alert('postHistory executed status true')
      }
    } catch (error: any) {
      console.log(error.message)
    } finally {
      // setMessage('')
      setAnswer('')
    }
  }

  const getUserHistory = async (userId: any) => {
    try {
      const userHistoryRespo = await fetch(
        `${baseUrl}/api/users/FindHistoryAll/${userId as string}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            authorization: token as string,
          },
        },
      )

      const userHistoryData = await userHistoryRespo.json()
      // setUserHistory(userHistoryData.data)
      // setThreadId((prevThreadId: any) => (prevThreadId = userHistoryData?.data[0]?.ThreadId))
      // const tid: string = userHistoryData?.data[0]?.ThreadId
      // tid && navigate(`/MainContent/${tid}`)

      // console.log('userHistoryData', userHistoryData)
      setUserHistory(userHistoryData?.data)
      // console.log('userHistoryData', userHistoryData?.data)
      // setThreadId((prevThreadId: any) => (prevThreadId = userHistoryData?.data[0]?.ThreadId))
      // const tid: string = userHistoryData?.data[0]?.ThreadId
      // tid && navigate(`/MainContent/${tid}`)
    } catch (error: any) {
      console.log('This Error occur in fetching history ', error.message)
    }
  }

  const editHistoryTItleAPi = async (title: any, historyId: any) => {
    try {
      const response = await fetch(`${baseUrl}/api/users/UpdateSerchTitle/${historyId as string}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
        body: JSON.stringify({
          SearchTitle: title,
        }),
      })

      const editHistoryResponseData = await response.json()

      if (editHistoryResponseData.status === true) {
        getUserHistory(userId)
      }
    } catch (error: any) {
      console.log('Error occur while editing title: ', error.message)
    }
  }

  const clearAllHistory = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/users/deleteallhistory${userId as string}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
      })

      const responseData = await response.json()

      if (responseData.status === true) {
        getUserHistory(userId)
      }
    } catch (error: any) {
      console.log('Delete chat history error ', error.message)
      getUserHistory(userId)
    } finally {
      getUserHistory(userId)
    }
  }

  // Edit QsetEditQuestionresuestion Scenario

  // Function For Python Server
  const fetchServerResponse = async () => {
    try {
      setEditQuestion(userInput)
      userInput !== '' && setLastAskedQuestion(userInput)
      const a = userInput

      setUserInput('')

      const response = await fetch(`${pythonUrl}/pyapi/search`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Promptquery: a }),
      })

      if (!response.ok) {
        throw new Error('Request failed')
      }

      if (response.body) {
        const reader = response.body.getReader()
        let chunks = ''

        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            setResponseComplete(true)
            break
          }
          chunks += new TextDecoder().decode(value)
        }

        const match = chunks.match(/total_tokens_consumed":(\d+)/)
        const totalTokensConsumed = match ? match[1] : null

        setTokenConsumed(totalTokensConsumed)
        setGlobalTokenConsumed(totalTokensConsumed)
        setServerResponse(chunks)
        // setIsLoading(false)
      }

      const data = await response.json()
    } catch (error) {
      console.error('An error occurred while submitting the form data', error)
    }
  }

  // Function to Store Edit History to Node Server

  const storeEditedHistory = async () => {
    try {
      const storeEditedHistoryResponse = await fetch(`${baseUrl}/api/users/StoreHistory1`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,

          key: 'Cross-Origin-Opener-Policy',
          value: 'same-origin allow-popups',
        },
        body: JSON.stringify({
          SearchTitle: lastAskedQuestion,
          SearchResult: serverResponse,
          UserId: userId,
          ParentId: questionId,
          ThreadId: threadId,
          Token: tokenConsumed,
        }),
      })

      const storeEditedHistoryResponseData = await storeEditedHistoryResponse.json()

      if (storeEditedHistoryResponseData.status === true) {
        setEditQuestionList(storeEditedHistoryResponseData.data)
        threadId !== '' &&
          threadId !== null &&
          threadId !== undefined &&
          getUserHistorybyThreadId(threadId)
      }
    } catch (error: any) {
      console.log('Error in storeEditedHistory Function : ', storeEditedHistory)
    }
  }

  // To get history by thread Id

  return (
    <>
      <SidebarContext.Provider
        value={{
          simplifyText,
          selectedTab,
          setSelectedTab,
          sendAnswerToLeftDrawer,
          setSendAnswerToLeftDrawer,
          isLoading,
          setIsLoading,
          lastAskedQuestion,
          setLastAskedQuestion,
          lastAnsweredQuestion,
          setLastAnsweredQuestion,
          globalTokenConsumed,
          setGlobalTokenConsumed,
          sidebarOpen,
          setSidebar,
          aiStorage,
          setAiStorage,
          inputMessage,
          setInputMessage,
          answer,
          setAnswer,
          userStatus,
          handleSearchCount,
          sendData,
          getHistory,

          message,
          setMessage,
          getUserHistory,
          userHistory,
          setUserHistory,
          editHistoryTItleAPi,
          allHistory,
          setAllHistory,
          setIsResponseComplete,
          threadId,
          setThreadId,
          clearAllHistory,

          buttonsDisabled,
          setButtonsDisabled,

          // Message Context

          messages,
          activeMessageContext,
          setActiveMessageContext,
          searchValue,
          setSearchValue,

          // Edit Question Functionality
          fetchServerResponse,
          editQuestionList,
          setQuestionId,
          userInput,
          setUserInput,

          // token consumption

          // thredId Deleted
          isThredIdDelete,
          setIsThreadIdDelete,
        }}
      >
        {children}
      </SidebarContext.Provider>
    </>
  )
}

export const useSidebarContext = () => {
  const sidebarContext = useContext(SidebarContext)
  if (sidebarContext === undefined) {
    throw new Error('useSidebarContext must be inside a SidebarProvider')
  }
  return sidebarContext
}

export default SidebarContext
