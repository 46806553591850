import React, { useState, useEffect } from 'react'
import { Link as RouteLink, useNavigate } from 'react-router-dom'
import {
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Link,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, type InferType } from 'yup'
import { type LoginBody } from '../../../types/auth'
import TextField from '../../../components/shared/TextField'
import { useAuthContext } from '../../../contexts'
// import { setItem } from '../../../lib/sessionStorage'
// import axios from 'axios'
import { toast } from 'react-toastify'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import baseUrl from '../../../config/baseUrl'
const schema = object({
  email: string().required(),
  password: string()
    .required()
    .test('no-spaces', 'Password must not contain spaces', (value) => !/\s/.test(value)),
})

type FormData = InferType<typeof schema>

const LoginForm = () => {
  const navigate = useNavigate()
  const { setAuthenticated } = useAuthContext()
  // remember me
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)

  const value = {
    email: '',
    password: '',
  }
  const [loginData, setLoginData] = useState(value)
  const [isButtonDisable, setIsbuttonDisable] = useState(true)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  useEffect(() => {
    const rememberedEmail = localStorage.getItem('rememberedEmail')
    const rememberedPassword = localStorage.getItem('rememberedPassword')
    const rememberMe = localStorage.getItem('rememberMe') === 'true'
    if (rememberMe) {
      setLoginData({
        email: rememberedEmail ?? '',
        password: rememberedPassword ?? '',
      })
      setRememberMe(true)
      onSubmit(loginData)
    }
  }, [])

  const handleLogin = (e: any) => {
    const { name, value } = e.target
    if (name === 'email') {
      const newValue = e.target.value.toLowerCase()

      setLoginData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }))
    } else {
      setLoginData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }

    const areLoginDataValuesEmpty = Object.values(loginData).some((val) => val.trim() === '')
    setIsbuttonDisable(areLoginDataValuesEmpty)
  }

  const onSubmit: SubmitHandler<LoginBody> = async (loginData) => {
    // setAuthenticated(true)

    try {
      const response: any = await fetch(`${baseUrl}/api/auths/login`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Origin: `${baseUrl}`,
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          Password: loginData.password,
          Email: loginData.email.toLowerCase(),
        }),
      })

      // if (!response.ok) {
      //   throw new Error('Login failed')
      // }
      console.log('Login response', response)
      const data = await response.json()

      console.log('Login response data', data)

      if (data.status === true) {
        // Handle successful response

        toast.success('Login successfully')
        localStorage.setItem('userId', data?.user?.id)
        localStorage.setItem('token', data?.token)
        localStorage.setItem('email', data?.user?.Email)

        // remember me
        if (rememberMe) {
          localStorage.setItem('rememberedEmail', loginData.email.toLowerCase())
          localStorage.setItem('rememberedPassword', loginData.password)
          localStorage.setItem('rememberMe', 'true')
        } else {
          localStorage.removeItem('rememberedEmail')
          localStorage.removeItem('rememberedPassword')
          localStorage.removeItem('rememberMe')
        }

        sessionStorage.setItem('userId', data?.user?.id)
        sessionStorage.setItem('token', data?.token)
        sessionStorage.setItem('firstName', data?.user?.FirstName)
        sessionStorage.setItem('lastName', data?.user?.LastName)
        sessionStorage.setItem('category', data?.user?.Category)
        sessionStorage.setItem('email', data?.user?.Email)
        sessionStorage.setItem('phone', data?.user?.PhoneNumber)
        sessionStorage.setItem('ExpiryData', data?.user?.SubscriptionExpiryDate)
        sessionStorage.setItem('isPaid', data?.user?.IsPaid)

        if (rememberMe) {
          sessionStorage.setItem('password', loginData?.password)
          sessionStorage.setItem('email', loginData?.email.toLowerCase())
        }

        navigate('/NewChat')
      } else {
        // Handle error response
        toast.error(data.message)
        navigate('/') // bypass
      }
    } catch (error: any) {
      console.error('An error occurred while submitting the form data', error)
      toast.error(error.message)

      // navigate('/') // bypass
    }
  }

  const [showPassword, setShowPassword] = useState<boolean>(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleCheckboxChange = (event: any) => {
    setRememberMe(event.target.checked)
    // You can perform additional actions here
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          gap: 2,
          overflow: 'auto',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, width: '100%' }}>
          <Controller
            name='email'
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <TextField
                type='email'
                name={name}
                // onChange={onChange}
                // onChange={ handleLogin}
                onChange={(e) => {
                  onChange(e)
                  handleLogin(e) // Call the separate handler
                }}
                value={value}
                error={errors.email?.message}
                label='Email'
              />
            )}
          />
          <Box>
            <Controller
              name='password'
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextField
                  // type='password'
                  type={showPassword ? 'text' : 'password'}
                  name={name}
                  onChange={(e) => {
                    onChange(e)
                    handleLogin(e)
                  }}
                  value={value}
                  label='Enter Password'
                  error={errors.password?.message}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={togglePasswordVisibility}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                slotProps={{
                  typography: {
                    variant: 'subtitle1',
                    color: 'text.primary',
                  },
                }}
                control={<Checkbox checked={rememberMe} onChange={handleCheckboxChange} />}
                label='Remember me'
              />
              <Link
                component={RouteLink}
                variant='subtitle1'
                to='/auth/forgot-password'
                underline='none'
              >
                Forgot Password?
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            gap: 1,
          }}
        >
          <Button type='submit' variant='contained'>
            Login
          </Button>
          <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant='subtitle1'>
              Not Registered Yet?
              <Link
                component={RouteLink}
                variant='subtitle2'
                to='/auth/register'
                underline='hover'
                color='textSecondary'
                ml={0.5}
              >
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

export default LoginForm
