import React, { useState } from 'react'
import { Box, Button, Grid, Stack, Typography, alpha, useTheme } from '@mui/material'
import { TrashIcon } from '../../../components/Icons'
import { DeleteAccountDialog } from '../../../components/shared/Dialogs'

const DeleteAccount = () => {
  const { palette } = useTheme()
  const [deleteAccountDialog, setDeleteAccountDialog] = useState<boolean>(false)

  const handleClick = () => {
    setDeleteAccountDialog(true)
  }
  
  const handleClose = () => {
    setDeleteAccountDialog(false)
  }
  
  return (
    <Stack spacing={2}>
      <Box
        display='flex'
        alignItems='center'
        bgcolor={alpha(palette.primary.main, 0.1)}
        borderRadius={1}
        height={33}
        px={1.5}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          mr={1.5}
          color='text.secondary'
        >
          <TrashIcon sx={{ width: 20, height: 20 }} />
        </Box>
        <Typography variant='subtitle2' color='text.secondary'>
          Delete Account
        </Typography>
      </Box>
      <Grid container px={1.5}>
        <Grid item md={7}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            p='10px 0px 10px 20px'
            borderRadius={1}
          >
            <Typography variant='subtitle1' color='textSecondary'>
              Delete Account
            </Typography>
            <Button
              variant='contained'
              color='error'
              sx={{ fontSize: 14, maxWidth: 81, p: 0, height: 37 }}
              onClick={handleClick}
            >
              Delete
            </Button>
          </Box>
        </Grid>
      </Grid>
      <DeleteAccountDialog open={deleteAccountDialog} onClose={handleClose} />
    </Stack>
  )
}

export default DeleteAccount
