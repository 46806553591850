import React, { useId } from 'react'
import { Dialog, DialogContent, Typography, Box, Button, Stack, Divider } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type InferType } from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import { likeFeedbackSchema } from '../../../lib/validation'
import { ThumbsUpIcon } from '../../Icons'
import TextField from '../TextField'
import { useSidebarContext } from '../../../contexts'
import baseUrl from '../../../config/baseUrl'

interface Props {
  open: boolean
  onClose: () => void
  historyId: string
  answer: string
}

type FormData = InferType<typeof likeFeedbackSchema>

const LikeFeedbackDialog = ({ open, onClose, historyId, answer }: Props) => {
  const { setButtonsDisabled } = useSidebarContext()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(likeFeedbackSchema),
    defaultValues: {
      message: '',
    },
  })

  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('userId')

  const userIdString = userId ? userId.toString() : ''

  const likeFeedback = async (data: any) => {
    try {
      const respo = await fetch(`${baseUrl}/api/like/addlike`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
        body: JSON.stringify({
          HistoryId: historyId,
          UserId: userId,
          PromptResp: answer,
          FeedBack: data?.message,
        }),
      })

      const respoData = await respo.json()

      if (respoData.status === true) {
        setButtonsDisabled(true)
      }
    } catch (e: any) {
      console.log('like api error : ', e.message)
      toast.error(e?.message)
    }
  }

  const onSubmit = (data: any) => {
    likeFeedback(data)
    onClose()
    reset({ message: '' })
  }

  return (
    <Dialog open={open} maxWidth='md' fullWidth>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: 4,
        }}
      >
        <Stack spacing={3} width={1}>
          <Stack spacing={2} width={1}>
            <Box display='flex' alignItems='center' gap={2} width={1}>
              <Box display='flex' alignItems='center' color='success.main'>
                <ThumbsUpIcon alreadyLiked={true} strokeColor='' />
              </Box>
              <Typography variant='body2' fontWeight={600} color='textSecondary'>
                Provide Additional Feedback
              </Typography>
            </Box>
            <Divider component='div' sx={{ borderColor: 'background.border' }} />
          </Stack>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Controller
                name='message'
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <TextField
                    name={name}
                    onChange={onChange}
                    value={value}
                    error={errors.message?.message}
                    label='Feedback'
                    multiline
                    rows={4}
                    placeholder='Enter Your Feedback'
                  />
                )}
              />
              <Stack direction='row' justifyContent='flex-end' spacing={2} width={1}>
                <Button
                  variant='outlined'
                  sx={{ p: 0, maxWidth: 114, height: 44 }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button variant='contained' type='submit' sx={{ p: 0, maxWidth: 94, height: 44 }}>
                  Submit
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default LikeFeedbackDialog
