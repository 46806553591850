import React, { useState, type ChangeEvent, useEffect, useContext } from 'react'

import { Avatar, Paper, CircularProgress, Stack, Typography, styled } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useSidebarContext, useAuthContext } from '../../contexts'

import { DRAWER_WIDTH } from '../ChatBoat/constants'

import Sidebar from '../ChatBoat/LeftSidebar/Actions/Sidebar'

import ActiveFilters from '../ChatBoat/MainContent/ActiveFilters'

import NewMessage from '../ChatBoat/MainContent/NewMessage'

import SendMessage from '../ChatBoat/MainContent/SendMessage'

import Messages from '../ChatBoat/MainContent/Messages'

import Question from '../ChatBoat/MainContent/Question'

import Message from '../ChatBoat/MainContent/Messages/Message'

import Stepper from '../ChatBoat/MainContent/Messages/Actions/Stepper'
import baseUrl from '../../config/baseUrl'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: any
}>(({ theme, open }) => ({
  display: 'flex',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  padding: theme.spacing(2, 4, 2, 2),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: theme.spacing(2),
  }),
}))

const MainContent = () => {
  const {
    setSelectedTab,
    isLoading,

    sidebarOpen,
    setSidebar,
    allHistory,
    aiStorage,
    setAiStorage,
    inputMessage,
    setMessage,
    setInputMessage,
    answer,
    setLastAskedQuestion,
    setAnswer,
    setThreadId,
    // setIsThreadIdDelete,
    // isThredIdDelete,

    threadId,
  } = useSidebarContext()

  const [storedNumber, setStoredNumber] = useState<number | null>(null)

  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const category = sessionStorage.getItem('category')
  const param = useParams()
  const { id, question } = param
  const storedValue = sessionStorage.getItem('userId')
  const userId = localStorage.getItem('userId')
  const parsedNumber = storedValue ? parseInt(storedValue, 10) : null
  const { setAuthenticated } = useAuthContext()
  const [mergedData, setMergedData] = useState<any>([])
  const [subSearchActiveSteps, setSubSearchActiveSteps] = useState<any>(allHistory.map(() => 0))
  const isPaid = sessionStorage.getItem('isPaid')

  const handleQuestionUpdate = (newQuestion: any) => {
    // Update the question in the state or perform any necessary actions
  }

  useEffect(() => {
    setStoredNumber(parsedNumber)
    setSelectedTab(1)
    // Make initial API request when storedNumber changes
    if (parsedNumber !== null) {
      fetchData(parsedNumber)
    }

    // Set up an interval to call fetchData every 1 minute
    const intervalId = setInterval(() => {
      if (parsedNumber !== null) {
        fetchData(parsedNumber)
        checkToken(parsedNumber)
      }
    }, 60000) // 60000 milliseconds = 1 minute

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    question && setLastAskedQuestion(question)
    question && setMessage(question)
  }, [question])

  useEffect(() => {
    const getUserHistory = async () => {
      try {
        const userHistoryRespo = await fetch(`${baseUrl}/api/users/gethistory/${id as string}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            authorization: token as string,
          },
        })

        const userHistoryData: any = await userHistoryRespo.json()

        setAiStorage(userHistoryData?.historydata)
      } catch (error: any) {
        console.log('This Error occur in fetching history ', error.message)
      }
    }

    threadId !== '' && getUserHistory()
  }, [id])

  useEffect(() => {
    id && setThreadId(id)
  }, [id])

  // For Pagination :

  useEffect(() => {
    const newArray = allHistory.map((obj: any) => {
      if (obj.subSerach) {
        return {
          ...obj,
          subSerach: [obj, ...obj.subSerach], // Move the object into subSerach
        }
      } else {
        return obj
      }
    })

    setMergedData(newArray)
  }, [id, allHistory])

  // Updated code for pagination 3

  useEffect(() => {
    setSubSearchActiveSteps(allHistory.map(() => 0))
  }, [allHistory])

  const checkToken = async (number: number) => {
    try {
      const response = await fetch(`${baseUrl}/api/logintoken/chectoken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userid: userId,
          token,
        }),
      })

      if (!response.ok) {
        throw Error(`${response.statusText} - ${response.url}`)
      }

      const data = await response.json()
      // console.log('data checktoken', data)
      if (data.status === false) {
        sessionStorage.clear()
        localStorage.clear()
        setAuthenticated(false)
        navigate('/auth/login', { replace: true })
      }
    } catch (error) {
      console.error('Error during API request:', error)
    }
  }
  const fetchData = async (number: number) => {
    try {
      const response = await fetch(`${baseUrl}/api/admin/userstatus/${number}`)

      if (response.ok) {
        const data = await response.json()

        if (data.Blocked === true) {
          localStorage.clear()
          navigate('/')
        }
        // Process the data as needed
      } else {
        console.error('API request failed')
      }
    } catch (error) {
      console.error('Error during API request:', error)
    }
  }

  const handleSubSearchStepChange = (index: number, step: number) => {
    setSubSearchActiveSteps((prevActiveSteps: any) => {
      const newActiveSteps = [...prevActiveSteps]
      newActiveSteps[index] = step
      return newActiveSteps
    })
  }

  return (
    <Main style={{ position: 'relative', marginTop: '5.5rem' }} open={sidebarOpen}>
      {isLoading && (
        <div
          style={{
            height: '100dvh',
            width: '100dvw',
            zIndex: 1000,
            position: 'absolute',
            // backgroundColor: 'rgba(202, 92, 92, 0.5)',
          }}
        >
          <h3 style={{ color: 'green', position: 'fixed', top: '45%', left: '45%' }}>
            Generating response...{' '}
          </h3>

          <CircularProgress
            disableShrink
            color='primary'
            thickness={4}
            style={{ position: 'fixed', top: '50%', left: '50%' }}
          />
        </div>
      )}

      {!sidebarOpen && <Sidebar title='Show Sidebar' placement='bottom' />}
      <Stack flexGrow={1} alignItems='center' position='relative'>
        <Stack flexGrow={1} spacing={2} width={1} maxWidth={1000}>
          <Stack flexGrow={1} spacing={2}>
            {/* <ActiveFilters /> */}
            {mergedData?.length === 0 && (
              <NewMessage category={category} aiStorage={aiStorage} setAiStorage={setAiStorage} />
            )}

            {mergedData?.map((each: any, eachIndex: number) => (
              <div key={each.id}>
                <Stepper
                  subSearchArray={each.subSerach || []}
                  activeStep={subSearchActiveSteps[eachIndex]}
                  handleStepChange={(step: number) => {
                    handleSubSearchStepChange(eachIndex, step)
                  }}
                />
              </div>
            ))}
          </Stack>
          <div style={{ position: 'relative' }}>
            <p>
              The provided information outlines the required experience, skill sets, and
              responsibilities for a position related to designing and delivering UI solutions for
              network operational processes. Below is a breakdown of the qualifications and skills
              needed for this role..:
            </p>
          </div>
          <SendMessage
            setInputMessage={setInputMessage}
            setAnswer={setAnswer}
            aiStorage={aiStorage}
            setAiStorage={setAiStorage}
          />
        </Stack>
      </Stack>
    </Main>
  )
}

export default MainContent
