import React, { useState } from 'react'
import { Button, Grid, InputAdornment } from '@mui/material'
import TextField from '../../../components/shared/TextField'
import { ChangePasswordDialog } from '../../../components/shared/Dialogs'

const ChangePassword = () => {
  const [changePasswordDialog, setChangePasswordDialog] = useState<boolean>(false)

  

  const handleClick = () => {
    setChangePasswordDialog(true)
  }

  const handleClose = () => {
    setChangePasswordDialog(false)
  }

  return (
    <Grid item md={7}>
      <TextField
        type='password'
        label='Password'
        name='password'
        value='123456'
        endAdornment={
          <InputAdornment position='end'>
            <Button sx={{ px: 2.5, py: '13px' }} onClick={handleClick}>
              Change
            </Button>
          </InputAdornment>
        }
      />
      <ChangePasswordDialog open={changePasswordDialog} onClose={handleClose} />
    </Grid>
  )
}

export default ChangePassword
