import React, { createContext, useState } from 'react'

const SidebarContext = createContext()

// eslint-disable-next-line react/prop-types
export const SidebarProvider = ({ children }) => {
  const [answerToLeftDrawer, setAnswerToLeftDrawer] = useState('')
  const [newChatClicked, setNewChatClicked] = useState(false)
  const [sendBtnClicked, setSendBtnClicked] = useState(false)
  const [historyClicked, setHistoryClicked] = useState(false)
  const [openVerifier, setOpenVerifier] = useState(false)
  const [dataInRightDrawer, setDataInRightDrawer] = useState('')

  React.useEffect(() => {
    // console.log('openVerifier', openVerifier)
  }, [openVerifier])

  return (
    <SidebarContext.Provider
      value={{
        sendBtnClicked,
        setSendBtnClicked,
        newChatClicked,
        setNewChatClicked,
        historyClicked,
        setHistoryClicked,
        answerToLeftDrawer,
        setAnswerToLeftDrawer,
        dataInRightDrawer,
        setDataInRightDrawer,
        openVerifier,
        setOpenVerifier,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export default SidebarContext
