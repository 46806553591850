import React, { useState } from 'react'
import { InputBase, TextField, type InputBaseProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { SearchIcon } from '../../../../components/Icons'
import { useSidebarContext } from '../../../../contexts'

interface Props {
  open: boolean
  onClick: React.Dispatch<React.SetStateAction<boolean>>
}

interface InputProps extends InputBaseProps {
  open: boolean
}

const Search = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
})

const SearchIconWrapper = styled('div')(({ theme }) => ({
  width: 32,
  height: 32,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  cursor: 'pointer',
  color: theme.palette.common.gray,
}))

const StyledInputBase = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'open',
})<InputProps>(({ theme, open }) => ({
  color: 'inherit',
  border: '0.5px solid rgba(0, 0, 0, 0.2)',
  height: 32,
  width: open ? 163 : 32,
  paddingRight: 32,
  backgroundColor: open ? theme.palette.background.dark : theme.palette.common.white,
  transition: theme.transitions.create(['width', 'background-color']),
  '& .MuiInputBase-input': {
    opacity: open ? 1 : 0,
    padding: open ? '7px 0px 7px 10px' : 0,
    fontSize: 12,
    '&::placeholder': {
      color: theme.palette.text.primary,
    },
  },
}))

const SearchInput = ({ open, onClick }: Props) => {
  // const [searchValue, setSearchValue] = useState<any>('') // State to store user input

  const { searchValue, setSearchValue } = useSidebarContext()

  const handleClick = () => {
    onClick(!open)
  }

  const handleChange = (e: any) => {
    const inputValue = e.target.value
    setSearchValue(e.target.value) // Update the search input value in state
  }

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    textFieldSetter: (value: string) => void,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      // handleSendClick()
      // if (inputQuestion.length !== 0) {
      //   userStatus()
      //   // setMessage('')
      // }

      // console.log('Enter pressed:', event.key) // Example action
    }
  }

  return (
    <Search>
      <SearchIconWrapper onClick={handleClick}>
        <SearchIcon />
      </SearchIconWrapper>
      {open && <TextField placeholder='Search' size='small' style={{ padding: 0, margin: 0 }} />}
      {/* <StyledInputBase
        open={open}
        placeholder='Search'
        onChange={(e) => {
          handleChange(e)
        }}
        onKeyDown={(event) => {
          handleEnterKeyPress(event, setInputQuestion)
        }}
      /> */}
    </Search>
  )
}

export default SearchInput
