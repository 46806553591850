import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type InferType } from 'yup'
import {
  Dialog,
  DialogContent,
  InputAdornment,
  IconButton,
  Typography,
  Box,
  Grid,
  Button,
  Stack,
  TextField,
  Divider,
} from '@mui/material'
import { KeyIcon } from '../../Icons'
// import TextField from '../TextField'

import { useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { ToastContainer, toast } from 'react-toastify'
import baseUrl from '../../../config/baseUrl'

const ChangePasswordDialogWithoutCurrentPassword = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const email = localStorage?.getItem('email')

  const resetToken = localStorage?.getItem('resetToken')

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      toast.error('New password and confirm password must be the same')
    } else if (newPassword === confirmPassword) {
      try {
        const response = await fetch(`${baseUrl}/api/auths/resetpass`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },

          body: JSON.stringify({
            Email: email,
            ResetToken: resetToken,
            Password: newPassword,
            ConfirmPassword: confirmPassword,
          }),
        })
        const responseData = await response.json()
        if (response.ok) {
          toast.success(responseData.message)
          localStorage.clear()
          navigate('/auth/login')
        } else {
          toast.error(responseData.message)
        }
      } catch (error) {
        toast.error('An error occurred while changing the password')
      }
      // Handle form submission logic here
    }
  }

  const toggleShowPassword = (field: string) => {
    switch (field) {
      case 'new':
        setShowNewPassword(!showNewPassword)
        break
      case 'confirm':
        setShowConfirmPassword(!showConfirmPassword)
        break
      default:
        break
    }
  }

  return (
    <Dialog maxWidth='sm' fullWidth open={open}>
      {' '}
      {/* Always open */}
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: 4,
        }}
      >
        <ToastContainer />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h4'>Change Password</Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <TextField
                sx={{
                  '& fieldset': { border: 'none' },
                  mt: 2,
                }}
                variant='outlined'
                fullWidth
                label='New Password'
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          toggleShowPassword('new')
                        }}
                        edge='end'
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant='outlined'
                sx={{
                  '& fieldset': { border: 'none' },
                  mt: 2,
                }}
                fullWidth
                label='Confirm Password'
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          toggleShowPassword('confirm')
                        }}
                        edge='end'
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ mt: 2 }} display='flex' justifyContent='flex-end'>
                <Button sx={{ mr: 3 }} variant='contained' color='primary' type='submit'>
                  Submit
                </Button>
                <Button variant='outlined' color='secondary' onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ChangePasswordDialogWithoutCurrentPassword
