import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import { Stack, styled, Typography, Button, Box } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { useSidebarContext } from '../../contexts'
import Question from './SHaredQuestion'
import Messages from './ShaedMessages'
import { DRAWER_WIDTH } from '../ChatBoat/constants'
import Stepper from './ShareChatStepper'

// Other imports...

// const Main = styled('main')({
//   display: 'flex',
//   flexGrow: 1,
//   padding: theme => theme.spacing(2, 4, 2, 2),
// });

// const Header = styled('div')(({ hideHeader }) => ({
//     display: hideHeader ? 'none' : 'block', // Conditionally hide the header
//   }));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: any
}>(({ theme, open }) => ({
  display: 'flex',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  padding: theme.spacing(2, 4, 2, 2),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: theme.spacing(2),
  }),
}))

const ShareChat = () => {
  const { aiStorage, setAiStorage, allHistory, setSidebar, setThreadId } = useSidebarContext()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const param = useParams()
  const { id } = param

  const navigate = useNavigate()

  const { simplifyText } = useSidebarContext()

  const mapObj: Record<string, string> = {
    '\\n': '<br />',
    '{"result":"': '',
    '","total_tokens_consumed":2589}': '',
  }

  // Rest of your component code...
  useEffect(() => {
    id && setThreadId(id)
    setIsOpen(false)
    setSidebar(false)
  }, [id])

  // Updated code for pagination 3

  const [mergedData, setMergedData] = useState<any>([])

  useEffect(() => {
    const newArray = allHistory.map((obj: any) => {
      if (obj.subSerach) {
        return {
          ...obj,
          subSerach: [obj, ...obj.subSerach], // Move the object into subSerach
        }
      } else {
        return obj
      }
    })

    setMergedData(newArray)
  }, [id, allHistory])

  const [subSearchActiveSteps, setSubSearchActiveSteps] = useState<any>(allHistory.map(() => 0))

  useEffect(() => {
    setSubSearchActiveSteps(allHistory.map(() => 0))
  }, [allHistory])

  const handleSubSearchStepChange = (index: number, step: number) => {
    // alert(index)

    setSubSearchActiveSteps((prevActiveSteps: any) => {
      const newActiveSteps = [...prevActiveSteps]

      newActiveSteps[index] = step

      return newActiveSteps
    })
  }

  const handleSignIn = () => {
    navigate('/auth/register')
  }

  const renderFormattedText = (text: string) => {
    const initialText: string = text

    const formattedText = initialText.replace(
      /\\n|{"result":"|","total_tokens_consumed":2589}/gi,
      (matched: string): string => {
        return mapObj[matched] || matched
      },
    )

    let modifiedString = ''

    modifiedString = formattedText.replace('}', '')

    modifiedString = modifiedString.replace('{"result":"', '')

    modifiedString = modifiedString.replace('","total_tokens_consumed":', '')

    modifiedString = modifiedString.replace(/\s*\d+\s*$/, '')

    modifiedString = modifiedString.replace(/\\n\\n/g, '\\n')

    modifiedString = modifiedString.replace(/\\n/g, ' ')

    const pattern = /\b\d+\.\s(?:[^.]*?(?<!\.)\s(?!.*?\.)|[^.\n])*?:/g

    modifiedString = modifiedString.replace(pattern, '<b>$&</b>')

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: modifiedString }} />
      </div>
    )
  }

  return (
    <>
      <Main open={isOpen}>
        <Stack flexGrow={1} alignItems='center' position='relative'>
          <Stack flexGrow={1} spacing={2} width={1} maxWidth={1000}>
            <Stack flexGrow={1} spacing={2}>
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Typography>
                  These results are produced by LawTech. Feel free to try more by signing in...
                </Typography>
                <Button variant='contained' onClick={handleSignIn}>
                  Sign In
                </Button>
              </Box>
              {mergedData?.map((each: any, eachIndex: number) => (
                <div key={each.id}>
                  <Stepper
                    subSearchArray={each.subSerach || []}
                    activeStep={subSearchActiveSteps[eachIndex]}
                    handleStepChange={(step: number) => {
                      handleSubSearchStepChange(eachIndex, step)
                    }}
                  />

                  {/* Render additional content inside <div> if needed */}
                  <div>
                    {/* Your content goes here */}
                    {/* For example, assuming there's a property named 'content', you can do: */}
                    <div
                      style={{ textAlign: 'justify', color: 'red', padding: '20px' }}
                      dangerouslySetInnerHTML={{ __html: each.content }}
                    />
                  </div>
                </div>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Main>
    </>
  )
}

export default ShareChat
