import React, { useState } from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'

import RectangleImage from '../../../assets/images/rectangle.jpg'
import ContactForm from './ContactForm'
import { ThankYouDialog } from '../../../components/shared/Dialogs'

interface formData {}

const ContactSection = () => {
  const [successfullyDialog, setSuccessfullyDialog] = useState<boolean>(false)

  const handleSubmit = (values: formData) => {
    setSuccessfullyDialog(true)
  }

  const handleClose = () => {
    setSuccessfullyDialog(false)
  }

  return (
    <Box id='contact-us' component='section' bgcolor='common.white' p={7.5}>
      <Container maxWidth='xl'>
        <Grid container spacing={7.5}>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <img src={RectangleImage} alt='contact-us' />
              <Box
                sx={{
                  position: 'absolute',
                  pr: 3.5,
                  py: 5,
                  maxWidth: 412,
                }}
              >
                <Typography variant='h2' color='white'>
                  Have a Doubt? We would love to help you !!
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ContactForm setSuccessfullyDialog={setSuccessfullyDialog} />
          </Grid>
        </Grid>
      </Container>
      <ThankYouDialog open={successfullyDialog} onClose={handleClose} />
    </Box>
  )
}

export default ContactSection
