import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Alert,
  Grid,
  Snackbar,
  MenuItem,
  Select,
  Typography,
  Box,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  useMediaQuery,
  Backdrop,
} from '@mui/material'
import { SendIcon } from '../../../../components/Icons'
import { useSidebarContext } from '../../../../contexts/Old_SidebarContext'
import CloseIcon from '@mui/icons-material/Close'
import { red } from '@mui/material/colors'
import LawTechLogo from '../../../../assets/images/Lawtech.png'
import SContext from '../../../../contexts/SidebarContext'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export default function LeftDrawer() {
  const {
    // answer,
    sendAnswerToLeftDrawer,
    userStatus,
    handleSearchCount,
    message,
    setMessage,
    simplifyText,
    getHistory,
    // sendAnswerToLeftDrawer,
  } = useSidebarContext()
  const iframeRef = useRef(null)
  const [open, setOpen] = React.useState(false)
  const [file, setFile] = useState(null)
  const [pdfUrl, setPdfUrl] = useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const userId = sessionStorage.getItem('userId')
  const [inputQuestion, setInputQuestion] = React.useState('')
  const promptRef = React.useRef(null)
  const fileInputRef = useRef(null)
  // const answerRef = React.useRef(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [backdropOpen, setBackdropOpen] = React.useState(false)
  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success')
  const [historyData, setHistoryData] = React.useState(null)
  const sendButtonColor = red[500]
  const isSmallScreen = useMediaQuery('(max-width: 600px)')
  const { answerToLeftDrawer } = useContext(SContext)

  const [isCopyPopupVisible, setIsCopyPopupVisible] = useState(false)
  const [selectedText, setSelectedText] = useState('')

  useEffect(() => {
    if (answerToLeftDrawer?.length > 0) {
      setOpen(true)
    }
  }, [answerToLeftDrawer])

  React.useEffect(() => {
    if (file) {
      setBackdropOpen(true)
    }
  }, [file])

  console.log('sendAnswerToLeftDrawer', sendAnswerToLeftDrawer, inputQuestion)

  const handleIframeLoad = () => {
    const iframe = iframeRef.current
    iframe.contentWindow.addEventListener('mouseup', handleSelection)
  }

  const handleSelection = () => {
    const iframe = iframeRef.current
    const selection = iframe.contentWindow.getSelection()
    if (selection && selection.toString()) {
      setSelectedText(selection.toString())
      setIsCopyPopupVisible(true)
    } else {
      setIsCopyPopupVisible(false)
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(selectedText)
    setIsCopyPopupVisible(false)
  }

  const handleCloseBackdrop = () => {
    setFile(null)
    setPdfUrl(null)
    setBackdropOpen(false)
  }
  const handleClose = () => {
    setOpen(false)
  }
  // const handleOpen = () => {
  //   console.log('inputQuestion', inputQuestion)
  //   setOpen(answerToLeftDrawer)
  // }

  // console.log('message, answer')
  const getHistoryData = async () => {
    // console.log('inputQuestion, userId', inputQuestion, userId, sessionStorage.getItem('userId'))
    const history = await getHistory(userId)
    history && setHistoryData(history)
    // history && console.log(history)
    // console.log('inputQuestion', inputQuestion)
  }

  React.useEffect(() => {
    getHistoryData()
  }, [])

  React.useEffect(() => {
    message && setIsLoading(false)
    message && getHistoryData()

    // answer && answerRef.current.value(answer)
  }, [message])

  const handleFileChange = (event) => {
    console.log(event.target.files[0])
    if (event.target.files[0]?.name.includes('pdf')) {
      setFile(event.target.files[0])
      const fileUrl = URL.createObjectURL(event.target.files[0])
      fileUrl && setPdfUrl(fileUrl)
    } else {
      setSnackbarMessage('Plese choose a .pdf or .docx file')
      setSnackbarSeverity('info')
      setSnackbarOpen(true)
    }

    console.log('Selected file:', event.target.files[0])
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleSendClick = async () => {
    setIsLoading(true)
    if (promptRef?.current?.value === '') {
      setSnackbarMessage('Please insert a prompt')
      setSnackbarSeverity('warning')
      setSnackbarOpen(true)
      // return
    } else {
      console.log('promptRef?.current?.value', promptRef?.current?.value)

      promptRef?.current?.value && setInputQuestion(promptRef?.current?.value)
      const uStatus = await userStatus(userId)
      // uStatus && console.log('uStatus', uStatus)
      uStatus?.status && handleSearchCount(promptRef?.current?.value, userId)
    }
  }

  const handleSelectQuestion = (index) => {
    setMessage(simplifyText(historyData[index]?.additionalInfo?.SearchResult))
    getHistoryData()
  }

  const MarkdownRenderer = () => {
    // console.log('message', message)

    return (
      <Box
        style={{
          maxHeight: 'calc(100vh - 160px)',
          width: '100%',
          overflow: 'auto',
          textAlign: 'justify',
          paddingRight: '10px',
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {/* {historyData && historyData[0]?.additionalInfo?.SearchResult} */}
          {/* {sendAnswerToLeftDrawer && sendAnswerToLeftDrawer} */}
          {message}
        </ReactMarkdown>
      </Box>
    )
  }

  return (
    <>
      <Box width='100%' position='relative' color='#000000'>
        <img
          src={LawTechLogo}
          alt='logo'
          style={{
            position: 'absolute',
            width: '64px',
            height: 'auto',
            top: '-20px',
            left: '10px',
          }}
        />

        <Box>
          <div
            style={{
              backgroundColor: '#fbd439',
              color: '#093f6b',
            }}
          >
            <h3 style={{ textAlign: 'center' }}>LawTech AI at your hand</h3>
          </div>
          <div style={{ display: 'grid', justifyContent: 'center' }}></div>
        </Box>
        {/* Backdrop for incoming answer */}
        <Box>
          {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
          <Backdrop
            sx={{
              p: '3rem',
              width: '50%',
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
          >
            <IconButton
              variant='h1'
              color='initial'
              onClick={handleClose}
              sx={{
                color: 'red',
                bgcolor: '#fff',
                cursor: 'pointer',
                position: 'absolute',
                top: '10px',
                right: '10px',
                '&:hover': {
                  bgcolor: '#eee7e7',
                },
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* <CircularProgress color='inherit' /> */}
            <Typography>{answerToLeftDrawer}</Typography>
          </Backdrop>
        </Box>

        {/*  Input prompt and send button */}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '5px 10px',
          }}
        >
          <TextField
            inputRef={promptRef}
            size='small'
            style={{
              width: '100%',
              marginRight: '12px',
              color: '#000',
            }}
          />
          <Button
            disabbled={!isLoading}
            variant={!isLoading ? 'contained' : 'outlined'}
            sx={{
              bgcolor: { sendButtonColor },
              minWidth: 50,
              width: 50,
              height: 50,
              p: 0,
            }}
            onClick={() => {
              handleSendClick()
            }}
          >
            {isLoading ? <CircularProgress color='warning' /> : <SendIcon />}
          </Button>
        </Box>

        <Grid container spacing={1} sx={{ p: '10px' }}>
          <Grid
            item
            xs={12}
            sm={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>History</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={{ p: '10px' }}>
              <Select>
                {historyData &&
                  historyData.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?.additionalInfo?.SearchTitle}
                      onClick={(e) => {
                        handleSelectQuestion(index)
                      }}
                    >
                      {item?.additionalInfo?.SearchTitle}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            itme
            xs={12}
            sm={5}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            {/* <Typography variant='p'>Note : Last asked 5 questions</Typography> */}

            <Button // button to upload file
              startIcon={<CloudUploadIcon />}
              variant='contained'
              sx={{
                height: isSmallScreen ? '30px' : '40px',
                width: isSmallScreen ? '120px' : '140px',
                marginRight: '10px',
                marginTop: isSmallScreen ? '0px' : '10px',
                color: 'black',
                fontSize: isSmallScreen ? '10px' : '',
              }}
              onClick={handleButtonClick}
            >
              <input
                ref={fileInputRef}
                type='file'
                accept='*.pdf, *.doc, *.docx'
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              Select File
            </Button>
          </Grid>
        </Grid>
        {/* history section */}

        {/* Answer section */}
        <Typography
          sx={{
            fontWeight: 'bold',
            p: '10px',
          }}
        >
          AI Response
        </Typography>
        <Box
          style={{
            border: '1.5px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '4px',
            margin: '10px',
            padding: '10px',
          }}
        >
          <MarkdownRenderer />
        </Box>

        <Box>
          {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
          <Backdrop
            sx={{
              p: '.2rem',
              width: '50%',
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={backdropOpen}
          >
            <IconButton
              variant='h1'
              color='initial'
              onClick={handleCloseBackdrop}
              style={{ zIndex: '1203' }}
              sx={{
                color: 'red',
                bgcolor: '#fff',
                cursor: 'pointer',
                position: 'absolute',
                top: '10px',
                right: '20px',
                '&:hover': {
                  bgcolor: '#eee7e7',
                },
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* <CircularProgress color='inherit' /> */}

            {pdfUrl && (
              <iframe
                style={{ zIndex: '1202' }}
                ref={iframeRef}
                src={pdfUrl}
                width='100%'
                height='800'
                title='PDF Viewer'
                allowFullScreen
                onLoad={handleIframeLoad}
              />
            )}
            {isCopyPopupVisible && (
              <div className='copy-popup'>
                <p>Selected text:</p>
                <pre>{selectedText}</pre>
                <button onClick={handleCopy}>Copy</button>
              </div>
            )}
          </Backdrop>
        </Box>
      </Box>
      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert elevation={6} onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}
