import React, { useState } from 'react'
import { Stack } from '@mui/material'

import History from './History'
import UpgradeSubscription from './UpgradeSubscription'
import NewChatButton from '../Actions/NewChatButton'
import SearchInput from '../Actions/Search'
import Sidebar from '../Actions/Sidebar'

const HistoryIndex: any = () => {
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const isPaid = sessionStorage.getItem('isPiad')
  return (
    <Stack>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <NewChatButton open={searchOpen} setSearchOpen={setSearchOpen} />
        <Stack direction='row' spacing={1.5}>
          <SearchInput onClick={setSearchOpen} open={searchOpen} />
          <Sidebar title='Hide Sidebar' placement='right' />
        </Stack>
      </Stack>
      <Stack spacing={2.5} flexGrow={1} justifyContent='space-between' marginBottom='100px'>
        <History />
        <UpgradeSubscription />
      </Stack>
    </Stack>
  )
}

export default HistoryIndex
