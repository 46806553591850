import React, { useState, useEffect } from 'react'
import baseUrl from '../../../../config/baseUrl'
import { Button, alpha, styled } from '@mui/material'
import { UserIcon } from '../../../../components/Icons'
import { UpgradeSubscriptionDialog } from '../../../../components/shared/Dialogs'
import { ToastContainer } from 'react-toastify'

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  left: '0px',
  right: '0px',
  bottom: '1px',
  maxWidth: '315px',
  color: theme.palette.secondary.main,
  background: alpha(theme.palette.primary.main, 0.9),
  border: '1px solid',
  borderColor: alpha(theme.palette.primary.main, 0.9),
  '&:hover': {
    background: alpha(theme.palette.primary.main, 1),
    borderColor: theme.palette.primary.main,
  },
}))

const UpgradeSubscription = () => {
  const [upgradeSubscriptionDialog, setUpgradeSubscriptionDialog] = useState<boolean>(false)

  const handleClick = () => {
    setUpgradeSubscriptionDialog(true)
  }

  const handleClose = () => {
    setUpgradeSubscriptionDialog(false)
  }

  return (
    <>
      <StyledButton
        startIcon={<UserIcon sx={{ width: 24, height: 24 }} />}
        onClick={() => {
          handleClick()
        }}
      >
        Upgrade Subscription
      </StyledButton>

      <UpgradeSubscriptionDialog open={upgradeSubscriptionDialog} onClose={handleClose} />
      {/* <ToastContainer/> */}
    </>
  )
}

export default UpgradeSubscription
