import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, Button, Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { replace } from 'formik'
import baseUrl from '../../../config/baseUrl'

const DownloadButtonDialog = () => {
  const navigate = useNavigate()

  const [open, setOpen] = useState<any>(true)
  const [userId, setuserId] = useState('')

  useEffect(() => {
    const uId = localStorage.getItem('userId')
    console.log('uId', uId)
    uId && setuserId((prevId) => (prevId = uId))
  }, [])

  const downloadFile = async () => {
    if (userId) {
      try {
        const downloadFileResponse = await fetch(`${baseUrl}/api/users/zipdownload/${userId}`, {
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        const downloadFileResponseData = await downloadFileResponse.text()
        if (downloadFileResponse.ok) {
          navigate('/NewChat', { replace: true })
          toast.success('Downloading started ')
        }
      } catch (error: any) {
        toast.error('Error in ownload export data : ', error.message)
      }
    }
  }

  const handleDownload = (userId: string) => {
    Number(userId) > 0 && window.location.reload()
    console.log('userId', userId)
    userId && window.open(`${baseUrl}/api/users/zipdownload/${userId}`, '_self')
    setTimeout(() => {
      window.close()
    }, 2000)
    // navigate('/NewChat', { replace: true })
  }

  const handleClose = () => {
    window.close()
  }

  return (
    <Dialog maxWidth='sm' fullWidth open={true}>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: 4,
        }}
      >
        <Typography variant='h5' gutterBottom>
          Click on download button to start download.
        </Typography>
        <Box display='flex' alignItems='center' gap={2} maxWidth={1000}>
          <Button
            variant='contained'
            onClick={(e) => {
              userId && handleDownload(userId)
            }}
            sx={{ p: 1, maxWidth: 120, height: 50, marginRight: 5 }}
          >
            Download
          </Button>

          <Button
            variant='outlined' // Use 'contained' to have the same background color
            onClick={handleClose}
            color='primary'
            sx={{ p: 1, maxWidth: 120, height: 50 }}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DownloadButtonDialog
