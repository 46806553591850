import React, { useState, type ReactNode } from 'react'
import {
  FormControl,
  FormLabel,
  Stack,
  Select,
  Divider,
  MenuItem,
  styled,
  type FormLabelProps,
  type SelectChangeEvent,
  Button,
} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import SubCategory from './SubCategory'
// import { SubCategories } from './data'

const StyledFormLabel = styled(FormLabel)<FormLabelProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  marginBottom: theme.spacing(1.5),
}))

const Categories = () => {
  const [showCategory1, setShowCategory1] = useState(false)
  const [showCategory2, setShowCategory2] = useState(false)
  const [showCategory3, setShowCategory3] = useState(false)
  // const [optionsForSubCategories, setOptionsForSubCategories] = useState([])
  const [selectedCategory1, setSelectedCategory1] = useState(' ')
  const [selectedCategory2, setSelectedCategory2] = useState(' ')
  const [category1, setCategory1] = useState<Array<{ value: string; label: string }>>([
    { value: 'acts', label: 'Acts' },
    { value: 'judgements', label: 'Judgements' },
    { value: 'formats', label: 'Formats / Notices' },
    { value: 'environmentalLaws', label: 'Environmental Laws' },
    { value: 'indianConstitution', label: 'Indian Constitution' },
    { value: 'civil', label: 'Civil' },
    { value: 'corporateLaw', label: 'Corporate Law' },
    { value: 'criminal', label: 'Criminal' },
    { value: 'taxation', label: 'Taxation' },
    { value: 'conditional', label: 'Conditional' },
    { value: 'internationalLaws', label: 'International Laws' },
    { value: 'intellectualProperty', label: 'Intellectual Property' },
  ])
  const [category2, setCategory2] = useState<Array<{ value: string; label: string }>>([])
  const [category3, setCategory3] = useState<Array<{ value: string; label: string }>>([])

  const navigate = useNavigate()

  const handleCategory1 = (e: SelectChangeEvent<string>, child: ReactNode) => {
    const { value } = e.target
    setSelectedCategory2(' ')
    setSelectedCategory1(value)

    if (value === 'acts') {
      setShowCategory2(false)
      navigate(`many-categories/${value}`)
      setShowCategory2(false)
    } else if (value === 'judgements') {
      setShowCategory2(false)
      navigate(`many-categories/${value}`)
    } else if (value === 'formats') {
      navigate(`many-categories/${value}`)
    } else if (value === 'environmentalLaws') {
      const subCatagories = [
        { value: 'biologicalDiversity', label: 'Biological Diversity' },
        { value: 'climateChangeAndPollution', label: 'Climate Change and Pollution' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'indianConstitution') {
      const subCatagories = [{ value: 'importantArticles', label: 'Important Articles' }]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'civil') {
      const subCatagories = [
        { value: 'economicOffences', label: 'Economic Offences' },
        { value: 'civilProcedure', label: 'Civil Procedure' },
        { value: 'familyLaw', label: 'Family Law' },
        { value: 'divorce', label: 'Divorce' },
        { value: 'marriage', label: 'Marriage' },
        { value: 'adoption', label: 'Adoption' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'corporateLaw') {
      const subCatagories = [
        {
          value: 'companiesBankruptcyAndInsolvency',
          label: 'Companies, Bankruptcy and Insolvency',
        },
        { value: 'contract', label: 'Contract' },
        { value: 'bankingAndFinance', label: 'Banking and Finance' },
        { value: 'companiesLaw', label: 'Companies Law' },
        { value: 'industrialRelation', label: 'Industrial Relation' },
        { value: 'labourAndEmploymentLaws', label: 'Labour and Employment Laws' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'criminal') {
      const subCatagories = [
        {
          value: 'roadSafety',
          label: 'Road Safety',
        },
        {
          value: 'sexualOffensesAndHarrashmentAct',
          label: 'Sexual Offenses and  Harassments act',
        },
        { value: 'murder', label: 'Murder' },
        { value: 'fraudAndTheft', label: 'Fraud and Theft' },
        { value: 'criminalProcedure', label: 'Criminal Procedure' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'taxation') {
      const subCatagories = [
        { id: 1, value: 'incomeTax', label: 'Income Tax' },
        { id: 2, value: 'indirectTax', label: 'Indirect Tax' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'conditional') {
      const subCatagories = [
        {
          id: 1,
          value: 'caseBased',
          label: 'Case Based',
        },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'internationalLaws') {
      const subCatagories = [
        {
          id: 1,
          value: 'domesticLegislationForInternationalLawAndConvention',
          label: 'Domestic Legislation for International Law and Convention',
        },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'intellectualProperty') {
      const subCatagories = [
        {
          id: 1,
          value: 'patentTrademarkAndGI',
          label: 'Patent Trademark and GI',
        },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else {
      toast.warning('Select proper input')
    }
  }

  const handleCategory2 = (e: SelectChangeEvent<string>, child: ReactNode) => {
    const { value } = e.target
    setSelectedCategory2(value)
    // console.log('value', value)
    if (value === 'biologicalDiversity') {
      navigate(`many-categories/${value}`)
    } else if (value === 'climateChangeAndPollution') {
      navigate(`many-categories/${value}`)
    } else if (value === 'importantArticles') {
      navigate(`many-categories/${value}`)
    } else if (value === 'economicOffences') {
      navigate(`many-categories/${value}`)
    } else if (value === 'civilProcedure') {
      navigate(`many-categories/${value}`)
    } else if (value === 'familyLaw') {
      navigate(`many-categories/${value}`)
    } else if (value === 'divorce') {
      navigate(`many-categories/${value}`)
    } else if (value === 'marriage') {
      navigate(`many-categories/${value}`)
    } else if (value === 'adoption') {
      navigate(`many-categories/${value}`)
    } else if (value === 'corporateLaw') {
      navigate(`many-categories/${value}`)
    } else if (value === 'contract') {
      navigate(`many-categories/${value}`)
    } else if (value === 'bankingAndFinance') {
      navigate(`many-categories/${value}`)
    } else if (value === 'companiesLaw') {
      navigate(`many-categories/${value}`)
    } else if (value === 'industrialRelation') {
      navigate(`many-categories/${value}`)
    } else if (value === 'labourAndEmploymentLaws') {
      navigate(`many-categories/${value}`)
    } else if (value === 'roadSafety') {
      navigate(`many-categories/${value}`)
    } else if (value === 'sexualOffensesAndHarrashmentact') {
      navigate(`many-categories/${value}`)
    } else if (value === 'murder') {
      navigate(`many-categories/${value}`)
    } else if (value === 'fraudAndTheft') {
      navigate(`many-categories/${value}`)
    } else if (value === 'criminalProcedure') {
      navigate(`many-categories/${value}`)
    } else if (value === 'incomeTax') {
      navigate(`many-categories/${value}`)
    } else if (value === 'indirectTax') {
      navigate(`many-categories/${value}`)
    } else if (value === 'caseBased') {
      navigate(`many-categories/${value}`)
    } else if (value === 'domesticLegislationForInternationalLawAndConvention') {
      navigate(`many-categories/${value}`)
    } else if (value === 'patentTrademarkAndGI') {
      navigate(`many-categories/${value}`)
    } else {
      toast.warning('Select proper input')
    }
  }

  return (
    <>
      <Stack spacing={2.5} paddingLeft='1rem'>
        <ToastContainer />
        <FormControl>
          <StyledFormLabel focused={false}>Acts / Judgements / Formats</StyledFormLabel>
          <Select
            onChange={handleCategory1}
            value={selectedCategory1}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value=' ' selected>
              <em>Select</em>
            </MenuItem>

            {category1?.map((category) => {
              return (
                <MenuItem key={category.value} value={category.value}>
                  {category.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <Divider />

        {showCategory2 && (
          <FormControl>
            <StyledFormLabel focused={false}>Sub-Categories</StyledFormLabel>
            <Select
              onChange={handleCategory2}
              value={selectedCategory2}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value=' ' selected>
                <em>Select</em>
              </MenuItem>
              {category2?.map((category) => {
                return (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
      </Stack>
    </>
  )
}

export default Categories
