import React, { useEffect, useState } from 'react'
import baseUrl from '../../../config/baseUrl'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

interface strokeColor {
  alreadyLiked: boolean
  strokeColor: string
}

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
  width: 20,
  height: 20,
  '& path': {
    stroke: 'currentcolor',
  },
})

const ThumbsUpIcon = ({
  alreadyLiked,
  strokeColor,
  ...props
}: SvgIconProps & { alreadyLiked: boolean; strokeColor: string }) => {
  // console.log(' in srccomponentsIconsIconsThumbsUp.tsx alreadyLiked', alreadyLiked)
  return (
    <StyledSvgIcon viewBox='0 0 20 20' {...props}>
      <g clipPath='url(#clip0_192_4623)'>
        <path
          d='M5.83301 8.3335V18.3335'
          strokeWidth='2'
          stroke={alreadyLiked ? '#fc0808' : 'none'}
          fill={alreadyLiked ? '#fc0808' : 'none'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.5003 4.89984L11.667 8.33317H16.5253C16.7841 8.33317 17.0393 8.39341 17.2707 8.50913C17.5021 8.62484 17.7034 8.79284 17.8587 8.99984C18.0139 9.20683 18.1188 9.44713 18.1651 9.7017C18.2114 9.95626 18.1978 10.2181 18.1253 10.4665L16.1837 17.1332C16.0827 17.4794 15.8722 17.7835 15.5837 17.9998C15.2952 18.2162 14.9443 18.3332 14.5837 18.3332H3.33366C2.89163 18.3332 2.46771 18.1576 2.15515 17.845C1.84259 17.5325 1.66699 17.1085 1.66699 16.6665V9.99984C1.66699 9.55781 1.84259 9.13389 2.15515 8.82133C2.46771 8.50877 2.89163 8.33317 3.33366 8.33317H5.63366C5.94373 8.33301 6.24761 8.24635 6.51112 8.08294C6.77464 7.91953 6.98734 7.68585 7.12533 7.40817L10.0003 1.6665C10.3933 1.67137 10.7801 1.76498 11.1318 1.94033C11.4836 2.11569 11.7911 2.36826 12.0315 2.67917C12.2719 2.99009 12.4389 3.3513 12.5201 3.73584C12.6013 4.12037 12.5945 4.51828 12.5003 4.89984V4.89984Z'
          strokeWidth='2'
          stroke={alreadyLiked ? '#05e166' : 'none'}
          fill={alreadyLiked ? '#05e166' : 'none'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_192_4623'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </StyledSvgIcon>
  )
}

export default ThumbsUpIcon
