import { Divider, Stack, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HistoryItem from './HistoryItem'
import { useSidebarContext } from '../../../../contexts'
import moment from 'moment'

const StyledDivider = styled(Divider)({
  fontSize: 14,
  color: 'rgba(0, 0, 0, 0.20)',
  '&:before': {
    height: 1,
    border: 'none',
    background: 'linear-gradient(90deg, rgba(217, 217, 217, 0.00) 0%, #D9D9D9 49.23%)',
  },
  '&:after': {
    height: 1,
    border: 'none',
    background: 'linear-gradient(-90deg, rgba(217, 217, 217, 0.00) 0%, #D9D9D9 49.23%)',
  },
})

const History = () => {
  const { userHistory, searchValue } = useSidebarContext()
  const [selectedQuestionId, setSelectedQuestionId] = useState(0)
  const [history, setHistory] = useState([])
  const [isLoadingHistory, setIsLoadingHistory] = useState(false)
  let createdAt: any = null
  const updatedHIstory = userHistory?.map((item) => item?.additionalInfo)
  // console.log('updatedHIstory', updatedHIstory)
  const filterHistory = updatedHIstory?.filter((item) =>
    item?.SearchTitle.toLowerCase().includes(searchValue.toLowerCase()),
  )
  // console.log('filterHistory', filterHistory)

  useEffect(() => {
    // setIsLoadingHistory(true)
    filterHistory && historyItems()
  }, [filterHistory])

  useEffect(() => {}, [history])

  // useEffect(() => {
  //   // selectedQuestionId && console.log('selectedQuestionId', selectedQuestionId)
  // }, [selectedQuestionId])

  if (!userHistory || searchValue === undefined) {
    return null // or return loading state or appropriate fallback
  }

  const historyItems: any = () => {
    const hItems: any = filterHistory?.map((item) => {
      // console.log('historyItem', item)
      return (
        <div key={item?.id}>
          {/* <StyledDivider>{moment(item.updatedAt).fromNow()}</StyledDivider> */}

          <StyledDivider>{getTimeLabel(item?.updatedAt)}</StyledDivider>

          <HistoryItem
            title={item?.SearchTitle}
            key={item?.id}
            historyId={item?.id}
            itemId={item?.id}
            threadId={item?.ThreadId}
            selectedQuestionId={selectedQuestionId}
            setSelectedQuestionId={setSelectedQuestionId}
          />
        </div>
      )
    })
    // console.log('hItems', hItems)
    return hItems
  }

  const getTimeLabel = (timestamp: any) => {
    const now = moment()
    const targetDate = moment(timestamp)
    const diffDays = now.diff(targetDate, 'days')

    if (diffDays === 0) {
      if (diffDays !== createdAt) {
        createdAt = 0
        return 'Today'
      }
    } else if (diffDays === 1) {
      if (diffDays !== createdAt) {
        createdAt = 1
        return 'Yesterday'
      }
    } else if (diffDays >= 2 && diffDays <= 7) {
      if (diffDays >= createdAt) {
        createdAt = 7
        return 'Past seven days'
      }
      return ''
    } else if (diffDays > 7) {
      if (diffDays >= createdAt) {
        createdAt = 30
        return targetDate.format('MMMM')
      }
      return ''
    }
  }

  return (
    <>
      <Stack spacing={3} sx={{ position: 'relative' }}>
        <Stack spacing={2}>
          <Stack spacing={1.5}>
            {filterHistory?.map((item) => {
              return (
                <div key={item?.id}>
                  {/* <StyledDivider>{moment(item.updatedAt).fromNow()}</StyledDivider> */}

                  <StyledDivider>{getTimeLabel(item?.updatedAt)}</StyledDivider>

                  <HistoryItem
                    title={item?.SearchTitle}
                    key={item?.id}
                    historyId={item?.id}
                    itemId={item?.id}
                    threadId={item?.ThreadId}
                    selectedQuestionId={selectedQuestionId}
                    setSelectedQuestionId={setSelectedQuestionId}
                  />
                </div>
              )
            })}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default History
