import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Link,
  Stack,
  Divider,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
} from '@mui/material'
import { LinkIcon, MessageSquareIcon, MoreHorizontalIcon, TrashIcon } from '../../Icons'
import moment from 'moment'
import DeleteShareLinkDialog from './DeleteShareLink'
import baseUrl from '../../../config/baseUrl'
import { ToastContainer, toast } from 'react-toastify'

interface Props {
  open: boolean
  onClose: () => void
}

const ManageLinkDialog = ({ open, onClose }: Props) => {
  const [anchorElOpen, setAnchorElOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const [deleteLinkDialog, setDeleteLinkDialog] = useState<boolean>(false)
  const [selectedLinkId, setSelectedLinkId] = useState<any>(null)

  const linkId = sessionStorage.getItem('linkId')

  const userId = localStorage.getItem('userId')

  const now = moment().format('DD MMMM YYYY')

  const [shareLinksArray, setShareLinksArray] = useState<any>([])

  const toggleDeleteLinkDialog = () => {
    setDeleteLinkDialog(!deleteLinkDialog)
  }

  const getShareLinks = async () => {
    try {
      const getShareLinksResponse = await fetch(
        `${baseUrl}/api/sharechat/getallsharechat/${userId as string}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      const getShareLinksResponseData = await getShareLinksResponse.json()
      if (getShareLinksResponseData.status === true) {
        setShareLinksArray(getShareLinksResponseData.sharechatdata)
      }

      if (getShareLinksResponseData.status === false) {
        setShareLinksArray([])
      }
    } catch (error: any) {
      console.log('Error in getting shareLink : ', error.message)
      toast.error(error?.message)
    }
  }

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOpen((prevOpen) => !prevOpen)
  }

  const deleteAllChatLinkes = async () => {
    try {
      const deleteAllChatLinkesResponse = await fetch(
        `${baseUrl}/api/sharechat/deleteallsharechat/${userId as string}`,
        {
          method: 'DELETE',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      const deleteAllChatLinkesResponseData = await deleteAllChatLinkesResponse.json()
      if (deleteAllChatLinkesResponseData.status === true) {
        getShareLinks()
      }
    } catch (error: any) {
      console.log('Delete all chatlinks : ', error.message)
      toast.error(error?.message)
    }
  }

  const deleteShareLink = async (linkId: any) => {
    try {
      const deleteShareLinkResponse = await fetch(
        `${baseUrl}/api/sharechat/deletesharechat/${linkId as string}`,
        {
          method: 'DELETE',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      const deleteShareLinkResponseData = await deleteShareLinkResponse.json()

      if (deleteShareLinkResponseData.status === true) {
        getShareLinks()
      }
    } catch (error: any) {
      console.log('delete share link error :', error.message)
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    getShareLinks()
  }, [])

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return false
    }
    setAnchorElOpen(false)
  }
  return (
    <Dialog open={open} maxWidth='md' fullWidth>
      <ToastContainer />
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: 4,
        }}
      >
        <Stack spacing={3} width={1}>
          <Stack spacing={2} width={1}>
            <Box display='flex' alignItems='center' gap={2} width={1}>
              <Box display='flex' alignItems='center' color='text.secondary'>
                <LinkIcon sx={{ width: 20, height: 20 }} />
              </Box>
              <Typography variant='body2' fontWeight={600} color='textSecondary'>
                Export Data
              </Typography>
            </Box>
            <Divider component='div' sx={{ borderColor: 'background.border' }} />
          </Stack>
          <Stack>
            <TableContainer sx={{}}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Shared On</TableCell>
                    <TableCell align='right'>
                      <IconButton
                        ref={anchorRef}
                        sx={{ width: 25, height: 25, p: 0, color: 'text.secondary' }}
                        onClick={handleToggle}
                      >
                        <MoreHorizontalIcon sx={{ width: 20, height: 20 }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shareLinksArray?.map((item: any) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell>
                            <Link
                              href={`${baseUrl}/ShareChat/${item.ThreadId as string}`}
                              target='_blank'
                              underline='none'
                              color='common.blue'
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <LinkIcon sx={{ width: 14, height: 14, mr: '10px' }} />
                              {item.Title}
                            </Link>
                          </TableCell>
                          <TableCell>{moment(item.updatedAt).format('DD MMMM YYYY')}</TableCell>
                          <TableCell align='right'>
                            <Box
                              display='flex'
                              alignItems='center'
                              justifyContent='flex-end'
                              gap={1.5}
                            >
                              <Link
                                href={`${baseUrl}/ShareChat/${item.ThreadId as string}`}
                                target='_blank'
                              >
                                <IconButton sx={{ width: 25, height: 25, color: 'text.secondary' }}>
                                  <MessageSquareIcon sx={{ width: 20, height: 20 }} />
                                </IconButton>
                              </Link>
                              <IconButton
                                sx={{ width: 25, height: 25, color: 'text.secondary' }}
                                onClick={() => {
                                  setDeleteLinkDialog(true)
                                  setSelectedLinkId(item?.id)
                                }}
                              >
                                <TrashIcon sx={{ width: 20, height: 20 }} />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  })}
                  {/* <TableRow>
                    <TableCell>
                      <Link
                        href='#'
                        underline='none'
                        color='common.blue'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <LinkIcon sx={{ width: 14, height: 14, mr: '10px' }} />
                        Want to enquire about civil jurisdiction
                      </Link>
                    </TableCell>
                    <TableCell>5 June 2023</TableCell>
                    <TableCell align='right'>
                      <Box display='flex' alignItems='center' justifyContent='flex-end' gap={1.5}>
                        <IconButton sx={{ width: 25, height: 25, color: 'text.secondary' }}>
                          <MessageSquareIcon sx={{ width: 20, height: 20 }} />
                        </IconButton>
                        <IconButton sx={{ width: 25, height: 25, color: 'text.secondary' }}>
                          <TrashIcon sx={{ width: 20, height: 20 }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Link
                        href='#'
                        underline='none'
                        color='common.blue'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <LinkIcon sx={{ width: 14, height: 14, mr: '10px' }} />
                        Want to enquire about civil jurisdiction
                      </Link>
                    </TableCell>
                    <TableCell>5 June 2023</TableCell>
                    <TableCell align='right'>
                      <Box display='flex' alignItems='center' justifyContent='flex-end' gap={1.5}>
                        <IconButton sx={{ width: 25, height: 25, color: 'text.secondary' }}>
                          <MessageSquareIcon sx={{ width: 20, height: 20 }} />
                        </IconButton>
                        <IconButton sx={{ width: 25, height: 25, color: 'text.secondary' }}>
                          <TrashIcon sx={{ width: 20, height: 20 }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      <Link
                        href='#'
                        underline='none'
                        color='common.blue'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <LinkIcon sx={{ width: 14, height: 14, mr: '10px' }} />
                        Want to enquire about civil jurisdiction
                      </Link>
                    </TableCell>
                    <TableCell>5 June 2023</TableCell>
                    <TableCell align='right'>
                      <Box display='flex' alignItems='center' justifyContent='flex-end' gap={1.5}>
                        <IconButton sx={{ width: 25, height: 25, color: 'text.secondary' }}>
                          <MessageSquareIcon sx={{ width: 20, height: 20 }} />
                        </IconButton>
                        <IconButton sx={{ width: 25, height: 25, color: 'text.secondary' }}>
                          <TrashIcon sx={{ width: 20, height: 20 }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            <DeleteShareLinkDialog
              open={deleteLinkDialog}
              onClose={() => {
                setDeleteLinkDialog(false)
              }}
              linkId={selectedLinkId}
              deleteShareLink={deleteShareLink}
            />
          </Stack>
          <Stack direction='row' justifyContent='flex-end' spacing={2} width={1}>
            <Button variant='outlined' sx={{ p: 0, maxWidth: 114, height: 44 }} onClick={onClose}>
              Cancel
            </Button>
            <Button variant='contained' sx={{ p: 0, maxWidth: 96, height: 44 }}>
              Update
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      <Popper
        open={anchorElOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{ p: 1.5, boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Button
                  variant='outlined'
                  color='error'
                  sx={{ maxWidth: '100%', height: 40, p: '8px 18px' }}
                  onClick={(event) => {
                    handleClose(event)
                    deleteAllChatLinkes()
                  }}
                >
                  Delete all shared links
                </Button>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Dialog>
  )
}

export default ManageLinkDialog
