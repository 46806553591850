import React from 'react'
import { Box, Container, Grid, Stack, Typography, useTheme } from '@mui/material'
import {
  BankOutlinedIcon,
  BotOutlinedIcon,
  LibraryOutlinedIcon,
  ListCheckOutlinedIcon,
  ScaleOutlinedIcon,
  SchoolOutlinedIcon,
} from '../../../components/Icons'

const gridData = [
  {
    title: 'Feature 1',
    icon: BankOutlinedIcon,
    description:
      'Law Tech offers you a real world courtroom experience with advanced simulation features, covering every aspect of the legal profession.',
  },
  {
    title: 'Feature 2',
    icon: SchoolOutlinedIcon,
    description:
      'The perfect platform for you to practise and learn everything you need to, while engaging in our mock trials, with the help of AI designed characters, cases and trials.',
  },
  {
    title: 'Feature 3',
    icon: LibraryOutlinedIcon,
    description:
      'Play your part as a part of the defence or the prosecution team, do your research, present your case and fight for your client’s justice in a realistic simulated setting.',
  },
  {
    title: 'Feature 4',
    icon: ScaleOutlinedIcon,
    description:
      'Although a simulation, the knowledge about laws, acts and sections are perfectly applicable over here in whatever case that you choose or get assigned to.',
  },
  {
    title: 'Feature 5',
    icon: ListCheckOutlinedIcon,
    description: 'Choose between the categories - Civil, Criminal, Bankruptcy.',
  },
  {
    title: 'Feature 6',
    icon: BotOutlinedIcon,
    description:
      'Last but not the least, your own AI assistant is there to help you with every query that you need help with. Just ask!',
  },
]
const WhyChooseUsSection = () => {
  const { spacing } = useTheme()
  return (
    <Box component='section' bgcolor='common.white' py={7.5}>
      <Container maxWidth='xl'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: 4,
            p: spacing(5, 10),
            background:
              'linear-gradient(289.45deg, rgba(255, 206, 14, 0.5) -18.07%, rgba(255, 219, 80, 0.5) 112.4%)',
          }}
        >
          <Box sx={{ width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h1'>Why Choose Us</Typography>
          </Box>
          <Grid container spacing={5}>
            {gridData.map((item, index) => (
              <Grid item md={4} key={index}>
                <Stack
                  spacing={3}
                  sx={{ bgcolor: 'common.white', borderRadius: 2, p: spacing(5, 4), height: 1 }}
                >
                  {React.createElement(item.icon, { sx: { width: 40, height: 40, fill: 'none' } })}
                  <Typography variant='h4'>{item.title}</Typography>
                  <Typography>{item.description}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default WhyChooseUsSection
