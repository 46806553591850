export const CivilSubCategories = [
  {
    label: 'Corruption',
    value: 'corruption',
  },
  {
    label: 'Divorce',
    value: 'divorce',
  },
  {
    label: 'Harassment',
    value: 'harassment',
  },
  {
    label: 'Inheritance',
    value: 'Inheritance',
  },
  {
    label: 'Construction',
    value: 'Construction',
  },
  {
    label: 'Medical',
    value: 'Medical',
  },
]

export const acts = [
  {
    id: 1,
    label: 'Section 5 of The Administrative Tribunals Act, 1985',
    value: 'Section 5 of The Administrative Tribunals Act, 1985',
  },
  {
    id: 2,
    label: 'Section 13 of The Actuaries Act, 2006',
    value: 'Section 13 of The Actuaries Act, 2006',
  },
  {
    id: 3,
    label: 'Section 2 of The Acquisition of Certain Area At Ayodhya Act, 1993',
    value: 'Section 2 of The Acquisition of Certain Area At Ayodhya Act, 1993',
  },
  {
    id: 4,
    label: 'Section 14 of The Academy Of Scientific And Innovative Research Act, 2011',
    value: 'Section 14 of The Academy Of Scientific And Innovative Research Act, 2011',
  },
  {
    id: 5,
    label: 'Section 20 of the Broach and Kaira Incumbered Estates Act, 1877',
    value: 'Section 20 of the Broach and Kaira Incumbered Estates Act, 1877',
  },
  {
    id: 6,
    label:
      'Section 12 of the Building and other Construction Workers (Regulation of Employment and Conditions of Service)Act, 1996',
    value:
      'Section 12 of the Building and other Construction Workers (Regulation of Employment and Conditions of Service)Act, 1996',
  },
  {
    id: 7,
    label: 'Section 3 of the Calcutta High Court (Extension of Jurisdiction) Act, 1953',
    value: 'Section 3 of the Calcutta High Court (Extension of Jurisdiction) Act, 1953',
  },
  {
    label: 'Section 9 of The Company Secretaries Act, 1980',
    value: 'Section 9 of The Company Secretaries Act, 1980',
  },
  {
    id: 8,
    label:
      'Section 26 of The Commission for Air Quality Management in National Capital Region and Adjoining Areas Act, 2021',
    value:
      'Section 26 of The Commission for Air Quality Management in National Capital Region and Adjoining Areas Act, 2021',
  },
  {
    id: 9,
    label: 'Section 15 of the Coinage Act, 2011',
    value: 'Section 15 of the Coinage Act, 2011',
  },
  {
    id: 10,
    label: 'Section 26 of THE COASTAL AQUACUL TURE AUTHORITY ACT, 2005',
    value: 'Section 26 of THE COASTAL AQUACUL TURE AUTHORITY ACT, 2005',
  },
  {
    id: 11,
    label: 'Section 4 of The Press Council Act, 1978',
    value: 'Section 4 of The Press Council Act, 1978',
  },
  {
    id: 12,
    label: 'Section 3 of Prevention of Terrorism Repeal Act, 2004',
    value: 'Section 3 of Prevention of Terrorism Repeal Act, 2004',
  },
]

export const judgements = [
  {
    id: 1,
    label:
      'What are the facts in the case Magic Eye Developers Pvt. Ltd versus M/s. Green Edge Infrastructure Pvt. Ltd. & Ors. Etc. (Civil Appeal Nos. 3634-37 of 2023)',
    value:
      'What are the facts in the case Magic Eye Developers Pvt. Ltd versus M/s. Green Edge Infrastructure Pvt. Ltd. & Ors. Etc. (Civil Appeal Nos. 3634-37 of 2023)',
  },
  {
    id: 2,
    label:
      'What are the issues in the case Sirajudheen versus Zeenath & Others(Civil Appeal No. 1491 of 2023 ?',
    value:
      'What are the issues in the case Sirajudheen versus Zeenath & Others(Civil Appeal No. 1491 of 2023 ?',
  },
  {
    id: 3,
    label:
      'What are the list of Citations and other references in case of Central Bureau of Investigation Versus Kapil Wadhawan & Another.Criminal Appeal No. 391 of 2024?',
    value:
      'What are the list of Citations and other references in case of Central Bureau of Investigation Versus Kapil Wadhawan & Another.Criminal Appeal No. 391 of 2024?',
  },
  {
    id: 4,
    label:
      'What is the Summary or background of the case of Dinesh Gupta Versus The State of Uttar Pradesh & Anr Criminal Appeal No(s). 214 of 2024?',
    value:
      'What is the Summary or background of the case of Dinesh Gupta Versus The State of Uttar Pradesh & Anr Criminal Appeal No(s). 214 of 2024?',
  },
  {
    id: 5,
    label:
      'What is the Judgment or Decision or Findings of Trial Court in case of Rajendhiran versus Muthaiammal @ Muthayee & Ors, Civil Appeal No.37 of 2024?',
    value:
      'What is the Judgment or Decision or Findings of Trial Court in case of Rajendhiran versus Muthaiammal @ Muthayee & Ors, Civil Appeal No.37 of 2024?',
  },
  {
    id: 6,
    label:
      'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Satish P. Bhatt Versus The State of Maharashtra & Anr.Criminal Appeal No.42 of 2024?',
    value:
      'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Satish P. Bhatt Versus The State of Maharashtra & Anr.Criminal Appeal No.42 of 2024?',
  },
  {
    id: 7,
    label:
      'What are the legal Provisions or Sections included in the case of State of NCT of Delhi versus Raj Kumar @ Lovepreet @Lovely Criminal Appeal No.43 of 2024?',
    value:
      'What are the legal Provisions or Sections included in the case of State of NCT of Delhi versus Raj Kumar @ Lovepreet @Lovely Criminal Appeal No.43 of 2024?',
  },
  {
    id: 8,
    label:
      'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Reliance Life Insurance Company Ltd. & Anr. Versus Jaya Wadhwani Civil Appeal No. 35 of 2024?',
    value:
      'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Reliance Life Insurance Company Ltd. & Anr. Versus Jaya Wadhwani Civil Appeal No. 35 of 2024?',
  },
  {
    id: 9,
    label:
      'What are the issue in the of case of Raja Gounder and Others Versus M. Sengodan and Others Civil Appeal No. 600 of 2024?',
    value:
      'What are the issue in the of case of Raja Gounder and Others Versus M. Sengodan and Others Civil Appeal No. 600 of 2024?',
  },
  {
    id: 10,
    label:
      'What are the list of Citations and other references in case of Mariam Fasihuddin & Another. v. State by Adugodi Police Station & Another.Criminal Appeal No. 335 of 2024?',
    value:
      'What are the list of Citations and other references in case of Mariam Fasihuddin & Another. v. State by Adugodi Police Station & Another.Criminal Appeal No. 335 of 2024?',
  },
  {
    id: 11,
    label:
      'What is the Judgment or Decision or Findings of the High Court in case of Gurdev Singh Bhalla Versus State of Punjab and Others Criminal Appeal No. 120 of 2024?',
    value:
      'What is the Judgment or Decision or Findings of the High Court in case of Gurdev Singh Bhalla Versus State of Punjab and Others Criminal Appeal No. 120 of 2024?',
  },
  {
    id: 12,
    label:
      'What is the Summary or background of the case of Delhi Development Authority Versus Hello Home Education Society Civil Appeal Nos. 3659-3660 of 2023?',
    value:
      'What is the Summary or background of the case of Delhi Development Authority Versus Hello Home Education Society Civil Appeal Nos. 3659-3660 of 2023?',
  },
  {
    id: 13,
    label:
      'What is the Summary or background of the case DBS Bank Limited Singapore versus Ruchi Soya Industries Limited and Another Civil Appeal No. 9133 of 2019?',
    value:
      'What is the Summary or background of the case DBS Bank Limited Singapore versus Ruchi Soya Industries Limited and Another Civil Appeal No. 9133 of 2019?',
  },
]

export const formats = [
  {
    id: 1,
    label: 'Format of the Notice Landlord to Tenant for Eviction',
    value: 'Format of the Notice Landlord to Tenant for Eviction',
  },
  {
    id: 2,
    label: 'Format of the Notice for Breach of Leave and Licence Agreement',
    value: 'Format of the Notice for Breach of Leave and Licence Agreement',
  },
  {
    id: 3,
    label: 'Format of the Notice for Compensation for Defect in Goods',
    value: 'Format of the Notice for Compensation for Defect in Goods',
  },
  {
    id: 4,
    label: 'Format of the Notice for Dishonour of Cheque',
    value: 'Format of the Notice for Dishonour of Cheque',
  },
  {
    id: 5,
    label:
      'Format of the Notice for Expulsion of a Partner or Notice under section 33 of Indian Partnership Act, 1932',
    value:
      'Format of the Notice for Expulsion of a Partner or Notice under section 33 of Indian Partnership Act, 1932',
  },
  {
    id: 6,
    label: 'Format of the Notice for Insurance Claim',
    value: 'Format of the Notice for Insurance Claim',
  },
  {
    id: 7,
    label: 'Format of the Notice to Terminate Contract',
    value: 'Format of the Notice to Terminate Contract',
  },
  {
    id: 8,
    label: 'Format of the Notice for Trespass and Damage to Property',
    value: 'Format of the Notice for Trespass and Damage to Property',
  },
  {
    id: 9,
    label: 'Format of the Notice to Refrain from Acting as Director of the Company',
    value: 'Format of the Notice to Refrain from Acting as Director of the Company',
  },
  {
    id: 10,
    label: 'Format of the Notice to Claim Maintenance',
    value: 'Format of the Notice to Claim Maintenance',
  },
  {
    id: 11,
    label: 'Format of reply notice to notice for eviction',
    value: 'Format of reply notice to notice for eviction',
  },
  {
    id: 12,
    label: 'Format of the Notice for Recovery of Money and Appointment of Arbitrator',
    value: 'Format of the Notice for Recovery of Money and Appointment of Arbitrator',
  },
  {
    id: 13,
    label: 'Format Application for grant of probate.',
    value: 'Format Application for grant of probate.',
  },
  {
    id: 14,
    label: 'Format for Petition for Dissolution of Marriage',
    value: 'Format for Petition for Dissolution of Marriage',
  },
  {
    id: 15,
    label: 'Format of Suit for Medical Negligence',
    value: 'Format of Suit for Medical Negligence',
  },
]

export const biologicalDiversity = [
  {
    id: 1,
    label: 'Give section 7 of the Biological Diversity Act, 2002',
    value: 'Give section 7 of the Biological Diversity Act, 2002',
  },
  {
    id: 2,
    label: 'Give section 7 of the Biological Diversity Act, 2002',
    value: 'Give section 7 of the Biological Diversity Act, 2002',
  },
  {
    id: 3,
    label: 'Give section 6 under the Compensatory Afforestation Fund Act, 2016',
    value: 'Give section 6 under the Compensatory Afforestation Fund Act, 2016',
  },
  {
    id: 4,
    label: 'Section 9 of The Indian Forest Act, 1927',
    value: 'Section 9 of The Indian Forest Act, 1927',
  },
  {
    id: 5,
    label: 'Explain all the provisions of section 2 under the Wild Life (Protection) Act, 1991',
    value: 'Explain all the provisions of section 2 under the Wild Life (Protection) Act, 1991',
  },
  {
    id: 6,
    label:
      'How the Compensatory Afforestation Fund  is used under Compensatory Afforestation Fund Act, 2016',
    value:
      'How the Compensatory Afforestation Fund  is used under Compensatory Afforestation Fund Act, 2016',
  },
]

export const climateChangeAndPollution = [
  {
    id: 1,
    label: 'Section 5 of The Air (Prevention and Control of Pollution)Act, 1981',
    value: 'Section 5 of The Air (Prevention and Control of Pollution)Act, 1981',
  },
  {
    id: 2,
    label:
      'Give all the provisions of section 8 of the Water Prevention And Control of Pollution Act, 1974',
    value:
      'Give all the provisions of section 8 of the Water Prevention And Control of Pollution Act, 1974',
  },
  {
    id: 3,
    label: 'Give section 2 of the Environment (Protection) Act, 1986',
    value: 'Give section 2 of the Environment (Protection) Act, 1986',
  },
]

export const importantArticles = [
  {
    id: 1,
    label: 'Give article 21 of Indian constitution',
    value: 'Give article 21 of Indian constitution',
  },
  {
    id: 2,
    label:
      'Which article of Indian constitution deals with freedom of religion. Give the details related to this article.',
    value:
      'Which article of Indian constitution deals with freedom of religion. Give the details related to this article.',
  },
  {
    id: 3,
    label: 'Which aricle of Indian constitution deals with freedom of speech.',
    value: 'Which aricle of Indian constitution deals with freedom of speech.',
  },
  {
    id: 4,
    label: 'What are the limitations of article 19 under the Indian constitution',
    value: 'What are the limitations of article 19 under the Indian constitution',
  },
  {
    id: 5,
    label: 'List some constitutional bodies according to Indian constitution',
    value: 'List some constitutional bodies according to Indian constitution',
  },
  {
    id: 6,
    label: 'List some non constitutional bodies according to Indian constitution',
    value: 'List some non constitutional bodies according to Indian constitution',
  },
  {
    id: 7,
    label: 'Is NITI Ayog a constitutional body',
    value: 'Is NITI Ayog a constitutional body',
  },
  {
    id: 8,
    label: 'Is Central Bureau of Investigation a constitutional body',
    value: 'Is Central Bureau of Investigation a constitutional body',
  },
  {
    id: 9,
    label: 'List the writs under article 32 of Indian constitution.',
    value: 'List the writs under article 32 of Indian constitution.',
  },
  {
    id: 10,
    label: 'How the president of India is elected.',
    value: 'How the president of India is elected.',
  },
  {
    id: 11,
    label: 'Give the preamble of Indian constitution.',
    value: 'Give the preamble of Indian constitution.',
  },
  {
    id: 12,
    label: 'What comes under ordinary jurisdiction of supreme court of India.',
    value: 'What comes under ordinary jurisdiction of supreme court of India.',
  },
  {
    id: 13,
    label: 'Which article of Indian constitution deals with right to education.',
    value: 'Which article of Indian constitution deals with right to education.',
  },
]

export const economicOffences = [
  {
    id: 1,
    label: 'Give all provisions of section 9 under the fugitive economic offenders act 2018.',
    value: 'Give all provisions of section 9 under the fugitive economic offenders act 2018.',
  },
  {
    id: 2,
    label: 'What is Section 3 of The Prevention of Money Laundering Act, 2002',
    value: 'What is Section 3 of The Prevention of Money Laundering Act, 2002',
  },
  {
    id: 3,
    label:
      'Which section of  The Prevention of Money Laundering Act, 2002 deals with the Procedure and manner of furnishing information',
    value:
      'Which section of  The Prevention of Money Laundering Act, 2002 deals with the Procedure and manner of furnishing information',
  },
  {
    id: 4,
    label: 'Give name of bodies or committees formed under Prevention of Corruption Act, 1988 ',
    value: 'Give name of bodies or committees formed under Prevention of Corruption Act, 1988 ',
  },
  {
    id: 5,
    label: 'What is the composition of Central Vigilance Commission',
    value: 'What is the composition of Central Vigilance Commission',
  },
]

export const civilProcedure = [
  {
    id: 1,
    label: 'Section 2 of The Code of Civil Procedure, 1908',
    value: 'Section 2 of The Code of Civil Procedure, 1908',
  },
  {
    id: 2,
    label:
      'Which section deals with the Costs for causing delay under the Code of Civil Procedure.',
    value:
      'Which section deals with the Costs for causing delay under the Code of Civil Procedure.',
  },
  {
    id: 3,
    label:
      'What are the Powers of the Court in executing transferred decree under  The Code of Civil Procedure.',
    value:
      'What are the Powers of the Court in executing transferred decree under  The Code of Civil Procedure.',
  },
  {
    id: 4,
    label:
      'Which section deals with  Arrest and detention under The Code of Civil Procedure, 1908.',
    value:
      'Which section deals with  Arrest and detention under The Code of Civil Procedure, 1908.',
  },
  {
    id: 5,
    label: 'What are the powers of magistrate under civil procedure code.',
    value: 'What are the powers of magistrate under civil procedure code.',
  },
]

export const familyLaw = [
  {
    id: 1,
    label: 'Give section 3 of Indian Succession Act.',
    value: 'Give section 3 of Indian Succession Act.',
  },
  {
    id: 2,
    label: 'Give section 4 of Protection of Women from Domestic Violence Act',
    value: 'Give section 4 of Protection of Women from Domestic Violence Act',
  },
  {
    id: 3,
    label: 'section 6 of Births, Deaths and Marriages Registration Act, 1886',
    value: 'section 6 of Births, Deaths and Marriages Registration Act, 1886',
  },
  {
    id: 4,
    label: 'Give  section 10 under The Hindu Adoptions and Maintenance Act, 1956',
    value: 'Give  section 10 under The Hindu Adoptions and Maintenance Act, 1956',
  },
]

export const divorce = [
  {
    id: 1,
    label: 'Explain Section 4 of The Divorce Act 1869',
    value: 'Explain Section 4 of The Divorce Act 1869',
  },
  {
    id: 2,
    label: 'Give section 2 of Muslim Women Protection of Rights on Divorce Act, 1986',
    value: 'Give section 2 of Muslim Women Protection of Rights on Divorce Act, 1986',
  },
  {
    id: 3,
    label: 'Give Section 10 of The Divorce Act 1869',
    value: 'Give Section 10 of The Divorce Act 1869',
  },
  {
    id: 4,
    label: 'What are the procedures of divorce under the Divorce act 1869.',
    value: 'What are the procedures of divorce under the Divorce act 1869.',
  },
  {
    id: 5,
    label: 'What is the section of mutual divorce under Hindu Marraige Act',
    value: 'What is the section of mutual divorce under Hindu Marraige Act',
  },
  {
    id: 6,
    label:
      'What does Restitution of Conjugal Rights expalins and under which section it is briefly explained ',
    value:
      'What does Restitution of Conjugal Rights expalins and under which section it is briefly explained ',
  },
]

export const marriage = [
  {
    id: 1,
    label: 'Give Section  2 of The Hindu Marriage Act, 1955 ',
    value: 'Give Section  2 of The Hindu Marriage Act, 1955 ',
  },
  {
    id: 2,
    label: 'Which section deals with Marriage Officers under The Special Marriage Act.',
    value: 'Which section deals with Marriage Officers under The Special Marriage Act.',
  },
  {
    id: 3,
    label: 'Give all provisions of Section 8 of The Special Marriage Act.',
    value: 'Give all provisions of Section 8 of The Special Marriage Act.',
  },
  {
    id: 4,
    label: 'Which section deals with Solemnization of marriage under The Foreign Marriage act',
    value: 'Which section deals with Solemnization of marriage under The Foreign Marriage act',
  },
  {
    id: 5,
    label: 'Give section 16 of Christian Marriage Act, 1872.',
    value: 'Give section 16 of Christian Marriage Act, 1872.',
  },
  {
    id: 6,
    label: 'Section 2 of The Prohibition of Child Marriage Act, 2006',
    value: 'Section 2 of The Prohibition of Child Marriage Act, 2006',
  },
  {
    id: 7,
    label: 'Give section 27 of Special Marriage Act, 1872.',
    value: 'Give section 27 of Special Marriage Act, 1872.',
  },
]

export const adoption = [
  {
    id: 1,
    label: 'What is Section 2 of The Hindu Adoptions and Maintenance Act.',
    value: 'What is Section 2 of The Hindu Adoptions and Maintenance Act.',
  },
  {
    id: 2,
    label: 'Give Section 2 of the Juvenile Justice Care and Protection of Children act.',
    value: 'Give Section 2 of the Juvenile Justice Care and Protection of Children act.',
  },
  {
    id: 3,
    label: 'What is Section 12 of The Hindu Adoptions and Maintenance Act.',
    value: 'What is Section 12 of The Hindu Adoptions and Maintenance Act.',
  },
  {
    id: 4,
    label: 'Give the provision of maintenance under the Hindu Adoptions and Maintenance Act.',
    value: 'Give the provision of maintenance under the Hindu Adoptions and Maintenance Act.',
  },
]

export const companiesBankruptcyAndInsolvency = [
  {
    id: 1,
    label: 'Section 2 The Recovery of Debts and Bankruptcy Act, 1993',
    value: 'Section 2 The Recovery of Debts and Bankruptcy Act, 1993',
  },
  {
    id: 2,
    label: 'Give all provisions of Section 3 of The Insolvency and Bankruptcy Code, 2016',
    value: 'Give all provisions of Section 3 of The Insolvency and Bankruptcy Code, 2016',
  },
  {
    id: 3,
    label: 'Give Section 4 of The Sick Industrial Companies act',
    value: 'Give Section 4 of The Sick Industrial Companies act',
  },
  {
    id: 4,
    label: 'Is there any body or committee to be established under Insolvency and Bankruptcy act',
    value: 'Is there any body or committee to be established under Insolvency and Bankruptcy act',
  },
  {
    id: 5,
    label: 'What are the powers of National Company Law Tribunal (NCLT) under Companies Act, 2013.',
    value: 'What are the powers of National Company Law Tribunal (NCLT) under Companies Act, 2013.',
  },
  {
    id: 6,
    label: 'How a company should be registered under Companies Act, 2013.',
    value: 'How a company should be registered under Companies Act, 2013.',
  },
]

export const contract = [
  {
    id: 1,
    label: 'Give all provisions of Section 2 of The Indian Contract Act.',
    value: 'Give all provisions of Section 2 of The Indian Contract Act.',
  },
  {
    id: 2,
    label: 'Which section deals with Undue influence under The Indian Contract Act',
    value: 'Which section deals with Undue influence under The Indian Contract Act',
  },
  {
    id: 3,
    label: 'Give section 9 of Contract Labour (Regulation and Abolition) Act, 1970',
    value: 'Give section 9 of Contract Labour (Regulation and Abolition) Act, 1970',
  },
  {
    id: 4,
    label: 'Give section 2 of The Securities Contracts Act, 1956',
    value: 'Give section 2 of The Securities Contracts Act, 1956',
  },
]

export const bankingAndFinance = [
  {
    id: 1,
    label: 'Section 2 of Reserve Bank of India Act, 1934',
    value: 'Section 2 of Reserve Bank of India Act, 1934',
  },
  {
    id: 2,
    label: 'Give all provisions of section 11 under Payment and Settlement Systems Act, 2007',
    value: 'Give all provisions of section 11 under Payment and Settlement Systems Act, 2007',
  },
  {
    id: 3,
    label: 'Explain section 10 of Banking Regulation Act, 1949',
    value: 'Explain section 10 of Banking Regulation Act, 1949',
  },
  {
    id: 4,
    label: 'Section 4 of The Securities and Exchange Board of India Act, 1992',
    value: 'Section 4 of The Securities and Exchange Board of India Act, 1992',
  },
  {
    id: 5,
    label: 'Give the powers of RBI under the Banking Regulation Act, 1949',
    value: 'Give the powers of RBI under the Banking Regulation Act, 1949',
  },
]

export const companiesLaw = [
  {
    id: 1,
    label: 'Explain all provisions of section 12 under Companies Act, 2013',
    value: 'Explain all provisions of section 12 under Companies Act, 2013',
  },
  {
    id: 2,
    label: 'Give all the provisions of section 9 under the Insolvency and Bankruptcy Code, 2016',
    value: 'Give all the provisions of section 9 under the Insolvency and Bankruptcy Code, 2016',
  },
  {
    id: 3,
    label: 'Give all the provisions of section 9 under the Insolvency and Bankruptcy Code, 2016',
    value: 'Give all the provisions of section 9 under the Insolvency and Bankruptcy Code, 2016',
  },
  {
    id: 4,
    label: 'Which section of the Competition Act, 2002 deals with Abuse of dominant position?',
    value: 'Which section of the Competition Act, 2002 deals with Abuse of dominant position?',
  },
  {
    id: 5,
    label: 'Which section of the Competition Act, 2002 deals with  establishment of Commission.',
    value: 'Which section of the Competition Act, 2002 deals with  establishment of Commission.',
  },
]

export const industrialRelation = [
  {
    id: 1,
    label: 'Give section 5 of Trade Unions Act, 1926',
    value: 'Give section 5 of Trade Unions Act, 1926',
  },
  {
    id: 2,
    label: 'Give the important sections of Industrial Disputes Act, 1947',
    value: 'Give the important sections of Industrial Disputes Act, 1947',
  },
  {
    id: 3,
    label: 'What is the definition of factory according to the Factories Act, 1948',
    value: 'What is the definition of factory according to the Factories Act, 1948',
  },
  {
    id: 4,
    label: 'Is there any body or committee established under Industrial Disputes act 1947',
    value: 'Is there any body or committee established under Industrial Disputes act 1947',
  },
  {
    id: 5,
    label: 'Is there any body or committee established under Industrial Disputes act 1947',
    value: 'Is there any body or committee established under Industrial Disputes act 1947',
  },
]

export const labourAndEmploymentLaws = [
  {
    id: 1,
    label: 'What should be minimum wage under Minimum Wages Act, 1948',
    value: 'What should be minimum wage under Minimum Wages Act, 1948',
  },
  {
    id: 2,
    label: 'Give important sections of Sexual Harassment of Women at Workplace 2013',
    value: 'Give important sections of Sexual Harassment of Women at Workplace 2013',
  },
  {
    id: 3,
    label: 'Give all the provisions of Section 13A of Payment of Wages Act, 1936',
    value: 'Give all the provisions of Section 13A of Payment of Wages Act, 1936',
  },
  {
    id: 4,
    label: 'Pension Fund Regulatory and Development Authority Act, 2013',
    value: 'Pension Fund Regulatory and Development Authority Act, 2013',
  },
  {
    id: 5,
    label: 'Which establishments come under Occupational Safety, Health and Working Conditions ',
    value: 'Which establishments come under Occupational Safety, Health and Working Conditions ',
  },
]

export const roadSafety = [
  {
    id: 1,
    label: 'Explain Section 185 of the Motor Vehicle Act.',
    value: 'Explain Section 185 of the Motor Vehicle Act.',
  },
  {
    id: 2,
    label: 'What are the penalty for drunken driving',
    value: 'What are the penalty for drunken driving',
  },
  {
    id: 3,
    label:
      'Which section deals with Restrictions on the granting of learners licences under Motor Vehicles Act.',
    value:
      'Which section deals with Restrictions on the granting of learners licences under Motor Vehicles Act.',
  },
  {
    id: 4,
    label: 'Which section deals with Renewal of driving licences under Motor Vehicle Act.',
    value: 'Which section deals with Renewal of driving licences under Motor Vehicle Act.',
  },
  {
    id: 5,
    label: 'What is the penalty for drving without helmet',
    value: 'What is the penalty for drving without helmet',
  },
  {
    id: 6,
    label: 'What is the penalty for driving without license',
    value: 'What is the penalty for driving without license',
  },
  {
    id: 7,
    label: 'What is the penalty for jumping the signal',
    value: 'What is the penalty for jumping the signal',
  },
]

export const sexualOffensesAndHarrashmentAct = [
  {
    id: 1,
    label: 'Give Section 6 of Sexual Harassment of Women at Workplace Act, 2013',
    value: 'Give Section 6 of Sexual Harassment of Women at Workplace Act, 2013',
  },
  {
    id: 2,
    label: 'Give all provisions of section 5 of Sexual Harassment of Women at Workplace Act, 2013',
    value: 'Give all provisions of section 5 of Sexual Harassment of Women at Workplace Act, 2013',
  },
  {
    id: 3,
    label: 'What was Criminal Law (Amendment) Act, 2013',
    value: 'What was Criminal Law (Amendment) Act, 2013',
  },
  {
    id: 4,
    label: 'Is there any body or committee under Sexual Harassment of Women at Workplace Act, 2013',
    value: 'Is there any body or committee under Sexual Harassment of Women at Workplace Act, 2013',
  },
  {
    id: 5,
    label: 'Which section of Indian penal code deals with eve teasing ',
    value: 'Which section of Indian penal code deals with eve teasing ',
  },
  {
    id: 6,
    label:
      'Which section talks about the outrage modesty of a women and under which act does this section is charged',
    value:
      'Which section talks about the outrage modesty of a women and under which act does this section is charged',
  },
]

export const murder = [
  {
    id: 1,
    label: 'Which section of Indian Penal Code deals with  murder.',
    value: 'Which section of Indian Penal Code deals with  murder.',
  },
  {
    id: 2,
    label: 'Which section is related to drunken driving under Motor vehicle Act',
    value: 'Which section is related to drunken driving under Motor vehicle Act',
  },
  {
    id: 3,
    label: 'Which section deals with Culpable homicide under the Indian Penal Code.',
    value: 'Which section deals with Culpable homicide under the Indian Penal Code.',
  },
  {
    id: 4,
    label: 'What are  the punishments for murder according to Indian Penal Code',
    value: 'What are  the punishments for murder according to Indian Penal Code',
  },
  {
    id: 5,
    label:
      'In how many days, a chargesheet is supposed to be filed by Police machinery in the offence of murder under Indian Penal Code',
    value:
      'In how many days, a chargesheet is supposed to be filed by Police machinery in the offence of murder under Indian Penal Code',
  },
]

export const fraudAndTheft = [
  {
    id: 1,
    label: 'Give section 210 of Indian Penal Code',
    value: 'Give section 210 of Indian Penal Code',
  },
  {
    id: 2,
    label: 'Give provisions of section 379 under Indian Penal Code.',
    value: 'Give provisions of section 379 under Indian Penal Code.',
  },
  {
    id: 3,
    label:
      'Which section deals with using a forged document as genuine under the Indian Penal Code?',
    value:
      'Which section deals with using a forged document as genuine under the Indian Penal Code?',
  },
  {
    id: 4,
    label: 'Give all the provisions of Section 471 of the Indian Penal Code ',
    value: 'Give all the provisions of Section 471 of the Indian Penal Code ',
  },
]

export const criminalProcedure = [
  {
    id: 1,
    label: 'Section 2 of The Code of Criminal Procedure 1973',
    value: 'Section 2 of The Code of Criminal Procedure 1973',
  },
  {
    id: 2,
    label: 'Section 4 of The Code of Criminal Procedure 1973',
    value: 'Section 4 of The Code of Criminal Procedure 1973',
  },
  {
    id: 3,
    label:
      'Which section deals with Classes of Criminal Courts under The Code of Criminal Procedure 1973',
    value:
      'Which section deals with Classes of Criminal Courts under The Code of Criminal Procedure 1973',
  },
  {
    id: 4,
    label: 'What are the powers of magistrate under the criminal procedure code.',
    value: 'What are the powers of magistrate under the criminal procedure code.',
  },
  {
    id: 5,
    label: 'What are the powers of Sessions Judge under the criminal procedure code.',
    value: 'What are the powers of Sessions Judge under the criminal procedure code.',
  },
  {
    id: 6,
    label: 'What is the section of regular bail application under code of criminal procedure',
    value: 'What is the section of regular bail application under code of criminal procedure',
  },
  {
    id: 7,
    label: 'What is the section of Anticipatory bail application under code of criminal procedure',
    value: 'What is the section of Anticipatory bail application under code of criminal procedure',
  },
  {
    id: 8,
    label: 'What does section 167 (2) of code of criminal procedure expalins',
    value: 'What does section 167 (2) of code of criminal procedure expalins',
  },
  {
    id: 9,
    label: 'What does section 205 of code of criminal procedure expalins',
    value: 'What does section 205 of code of criminal procedure expalins',
  },
  {
    id: 10,
    label: 'What does section 317 of code of criminal procedure expalins',
    value: 'What does section 317 of code of criminal procedure expalins',
  },
]

export const incomeTax = [
  {
    id: 1,
    label: 'Give section 80D of Income Tax Act 1961.',
    value: 'Give section 80D of Income Tax Act 1961.',
  },
  {
    id: 2,
    label:
      'Which section deals with Deduction in respect of donations to certain funds, charitable institutions under Income Tax Act 1961',
    value:
      'Which section deals with Deduction in respect of donations to certain funds, charitable institutions under Income Tax Act 1961',
  },
  {
    id: 3,
    label: 'Explain section 4 of Income Tax Act, 1961.',
    value: 'Explain section 4 of Income Tax Act, 1961.',
  },
  {
    id: 4,
    label: 'What are the punishments of not paying income tax  under Income Tax Act 1961.',
    value: 'What are the punishments of not paying income tax  under Income Tax Act 1961.',
  },
  {
    id: 5,
    label:
      'Give important provisions of Black Money Undisclosed Foreign Income and Assets and Imposition of Tax Act',
    value:
      'Give important provisions of Black Money Undisclosed Foreign Income and Assets and Imposition of Tax Act',
  },
]

export const indirectTax = [
  {
    id: 1,
    label: 'Give Section 1 of The Goods and Services Tax (Compensation to States) Act, 2017',
    value: 'Give Section 1 of The Goods and Services Tax (Compensation to States) Act, 2017',
  },
  {
    id: 2,
    label: 'Section 3 of The Goods and Services Tax (Compensation to States) Act, 2017',
    value: 'Section 3 of The Goods and Services Tax (Compensation to States) Act, 2017',
  },
  {
    id: 3,
    label: 'Section 4 of Integrated Goods and Services Tax Act, 2017',
    value: 'Section 4 of Integrated Goods and Services Tax Act, 2017',
  },
  {
    id: 4,
    label: 'Explain all provisions of section 9 under the Central Goods and Services Tax Act, 2017',
    value: 'Explain all provisions of section 9 under the Central Goods and Services Tax Act, 2017',
  },
  {
    id: 5,
    label: 'Is there any body established under the Central Goods and Services Tax Act, 2017',
    value: 'Is there any body established under the Central Goods and Services Tax Act, 2017',
  },
  {
    id: 6,
    label: 'Who are the members of Goods and Services tax council',
    value: 'Who are the members of Goods and Services tax council',
  },
]

export const caseBased = [
  {
    id: 1,
    label:
      'I was in possession of marijuana when police office caught me. now that officer is blackmailing me and is demanding 5000 rupees to let go the matter. How can i protect myself',
    value:
      'I was in possession of marijuana when police office caught me. now that officer is blackmailing me and is demanding 5000 rupees to let go the matter. How can i protect myself',
  },
  {
    id: 2,
    label:
      'A vendor given me a check. When I submited in bank it got bounced. What remedies do I have.',
    value:
      'A vendor given me a check. When I submited in bank it got bounced. What remedies do I have.',
  },
  {
    id: 3,
    label: 'A police officer is harrashing me. What remedies do I have.',
    value: 'A police officer is harrashing me. What remedies do I have.',
  },
  {
    id: 4,
    label: 'An advocate is charging more fees to win the case. what can I do.',
    value: 'An advocate is charging more fees to win the case. what can I do.',
  },
]

export const domesticLegislationForInternationalLawAndConvention = [
  {
    id: 1,
    label:
      'Which act was passed to give affect to United Nations Convention on the Law of the Sea.',
    value:
      'Which act was passed to give affect to United Nations Convention on the Law of the Sea.',
  },
  {
    id: 2,
    label: 'Give Article 93 of The Geneva Conventions Act, 1960',
    value: 'Give Article 93 of The Geneva Conventions Act, 1960',
  },
  {
    id: 3,
    label: 'Give article 253 of Indian constitution.',
    value: 'Give article 253 of Indian constitution.',
  },
  {
    id: 4,
    label:
      'Which article of Indian constitution give power for implementing any treaty, agreement, or convention',
    value:
      'Which article of Indian constitution give power for implementing any treaty, agreement, or convention',
  },
  {
    id: 5,
    label: 'Which international body replaced the General Agreement on Tariffs and Trade (GATT)',
    value: 'Which international body replaced the General Agreement on Tariffs and Trade (GATT)',
  },
  {
    id: 6,
    label: 'Which agreement is related with the establishment of WTO',
    value: 'Which agreement is related with the establishment of WTO',
  },
]

export const patentTrademarkAndGI = [
  {
    id: 1,
    label: 'List important provisions under the Patents Act, 1970',
    value: 'List important provisions under the Patents Act, 1970',
  },
  {
    id: 2,
    label: 'Give important provisions of section 5 under the Designs Act, 2000',
    value: 'Give important provisions of section 5 under the Designs Act, 2000',
  },
  {
    id: 3,
    label: 'Give all the provisions of section 9 under the Bureau of Indian Standards Act, 2016',
    value: 'Give all the provisions of section 9 under the Bureau of Indian Standards Act, 2016',
  },
  {
    id: 4,
    label:
      'Which section deals with the Prohibition of manufacture and sale of certain drugs and cosmetics under Drugs and Cosmetics Act, 1940',
    value:
      'Which section deals with the Prohibition of manufacture and sale of certain drugs and cosmetics under Drugs and Cosmetics Act, 1940',
  },
  {
    id: 5,
    label:
      'List some items declared as geographical indicators under the Geographical Indications of Goods act 2002',
    value:
      'List some items declared as geographical indicators under the Geographical Indications of Goods act 2002',
  },
  {
    id: 6,
    label: 'Give section 10 of Designs Act, 2000',
    value: 'Give section 10 of Designs Act, 2000',
  },
]

// ==========================================================================
export const CivilJudgesCategories = [
  {
    label: 'Dhananjaya Y. Chandrachud',
    value: 'dhananjaya y. chandrachud',
  },
  {
    label: 'Sanjay Kishan Kaul',
    value: 'sanjay kishan kaul',
  },
  {
    label: 'Sanjiv Khanna',
    value: 'sanjiv khanna',
  },
  {
    label: 'Bhushan Ramkrishna Gavai',
    value: 'bhushan ramkrishna gavai',
  },
]

export const CivilCourtsCategories = [
  {
    label: 'Supreme Court',
    value: 'supreme court',
  },
  {
    label: 'High Courts',
    value: 'high courts',
  },
  {
    label: 'District Courts',
    value: 'district courts',
  },
  {
    label: 'Lok Adalats/Village Courts',
    value: 'lok adalats',
  },
  {
    label: 'Tribunals',
    value: 'tribunals',
  },
]

export const CriminalSubCategories = [
  {
    label: 'Murder',
    value: 'murder',
  },
  {
    label: 'Dacoity',
    value: 'dacoity',
  },
  {
    label: 'Fraud',
    value: 'fraud',
  },
]

export const Judjements = [
  {
    label:
      'What are the facts in the case Magic Eye Developers Pvt. Ltd versus M/s. Green Edge Infrastructure Pvt. Ltd. & Ors. Etc. (Civil Appeal Nos. 3634-37 of 2023)',
    value:
      'What are the facts in the case Magic Eye Developers Pvt. Ltd versus M/s. Green Edge Infrastructure Pvt. Ltd. & Ors. Etc. (Civil Appeal Nos. 3634-37 of 2023)',
  },
  {
    label:
      'What are the issues in the case Sirajudheen versus Zeenath & Others(Civil Appeal No. 1491 of 2023 ?',
    value:
      'What are the issues in the case Sirajudheen versus Zeenath & Others(Civil Appeal No. 1491 of 2023 ?',
  },
  {
    label:
      'What are the list of Citations and other references in case of Central Bureau of Investigation Versus Kapil Wadhawan & Another.Criminal Appeal No. 391 of 2024?',
    value:
      'What are the list of Citations and other references in case of Central Bureau of Investigation Versus Kapil Wadhawan & Another.Criminal Appeal No. 391 of 2024?',
  },
  {
    label:
      'What is the Summary or background of the case of Dinesh Gupta Versus The State of Uttar Pradesh & Anr Criminal Appeal No(s). 214 of 2024?',
    value:
      'What is the Summary or background of the case of Dinesh Gupta Versus The State of Uttar Pradesh & Anr Criminal Appeal No(s). 214 of 2024?',
  },
  {
    label:
      'What is the Judgment or Decision or Findings of Trial Court in case of Rajendhiran versus Muthaiammal @ Muthayee & Ors, Civil Appeal No.37 of 2024?',
    value:
      'What is the Judgment or Decision or Findings of Trial Court in case of Rajendhiran versus Muthaiammal @ Muthayee & Ors, Civil Appeal No.37 of 2024?',
  },
  {
    label:
      'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Satish P. Bhatt Versus The State of Maharashtra & Anr.Criminal Appeal No.42 of 2024?',
    value:
      'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Satish P. Bhatt Versus The State of Maharashtra & Anr.Criminal Appeal No.42 of 2024?',
  },
  {
    label:
      'What are the legal Provisions or Sections included in the case of State of NCT of Delhi versus Raj Kumar @ Lovepreet @Lovely Criminal Appeal No.43 of 2024?',
    value:
      'What are the legal Provisions or Sections included in the case of State of NCT of Delhi versus Raj Kumar @ Lovepreet @Lovely Criminal Appeal No.43 of 2024?',
  },
  {
    label:
      'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Reliance Life Insurance Company Ltd. & Anr. Versus Jaya Wadhwani Civil Appeal No. 35 of 2024?',
    value:
      'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Reliance Life Insurance Company Ltd. & Anr. Versus Jaya Wadhwani Civil Appeal No. 35 of 2024?',
  },
  {
    label:
      'What are the issue in the of case of Raja Gounder and Others Versus M. Sengodan and Others Civil Appeal No. 600 of 2024?',
    value:
      'What are the issue in the of case of Raja Gounder and Others Versus M. Sengodan and Others Civil Appeal No. 600 of 2024?',
  },
  {
    label:
      'What are the list of Citations and other references in case of Mariam Fasihuddin & Another. v. State by Adugodi Police Station & Another.Criminal Appeal No. 335 of 2024?',
    value:
      'What are the list of Citations and other references in case of Mariam Fasihuddin & Another. v. State by Adugodi Police Station & Another.Criminal Appeal No. 335 of 2024?',
  },
  {
    label:
      'What is the Judgment or Decision or Findings of the High Court in case of Gurdev Singh Bhalla Versus State of Punjab and Others Criminal Appeal No. 120 of 2024?',
    value:
      'What is the Judgment or Decision or Findings of the High Court in case of Gurdev Singh Bhalla Versus State of Punjab and Others Criminal Appeal No. 120 of 2024?',
  },
  {
    label:
      'What is the Summary or background of the case of Delhi Development Authority Versus Hello Home Education Society Civil Appeal Nos. 3659-3660 of 2023?',
    value:
      'What is the Summary or background of the case of Delhi Development Authority Versus Hello Home Education Society Civil Appeal Nos. 3659-3660 of 2023?',
  },
  {
    label:
      'What is the Summary or background of the case DBS Bank Limited Singapore versus Ruchi Soya Industries Limited and Another Civil Appeal No. 9133 of 2019?',
    value:
      'What is the Summary or background of the case DBS Bank Limited Singapore versus Ruchi Soya Industries Limited and Another Civil Appeal No. 9133 of 2019?',
  },
]

export const CriminalJudgesCategories = [
  {
    label: 'Bhushan Ramkrishna Gavai',
    value: 'bhushan ramkrishna gavai',
  },
  {
    label: 'Sanjiv Khanna',
    value: 'sanjiv khanna',
  },
]

export const CriminalCourtsCategories = [
  {
    label: 'Supreme Court',
    value: 'supreme court',
  },
  {
    label: 'High Courts',
    value: 'high courts',
  },
  {
    label: 'Courts of Session',
    value: 'courts of session',
  },
  {
    label: 'Judicial and Executive Magistrates',
    value: 'judicial and executive magistrates',
  },
]

export const BankruptcySubCategories = [
  {
    label: 'Insolvency',
    value: 'insolvency',
  },
  {
    label: 'Dissolution',
    value: 'dissolution',
  },
  {
    label: 'Restructuring',
    value: 'restructuring',
  },
]

export const BankruptcyVariableCategories = [
  {
    label: 'XYZ',
    value: 'xyz',
  },
  {
    label: 'GGG',
    value: 'ggg',
  },
]

export const BankruptcyJudgesCategories = [
  {
    label: 'Mr. Justice Aniruddha Bose',
    value: 'aniruddha bose',
  },
  {
    label: ' Mr. Justice Ajjikuttira Somaiah Bopanna',
    value: 'ajjikuttira somaiah bopanna',
  },
]

export const BankruptcyCourtsCategories = [
  {
    label: ' National Company Law Tribunal (NCLT)',
    value: 'NCLT',
  },
  {
    label: ' National Company Law Appellate  Tribunal (NCLAT)',
    value: 'NCLAT',
  },
  {
    label: 'Insolvency and Bankruptcy Board of India (IBBI)',
    value: 'IBBI',
  },
]
